import React, { useState, useEffect, useRef } from 'react'
import { feature, mesh } from 'topojson-client'
import * as d3 from 'd3'

export default function ChoroplethMap({ mdata }) {
    const [topodata, setTopodata] = useState()
    const [vwAttr, setVwAttr] = useState()
    const [features, setFeatures] = useState()
    const [defLabel,setDefLabel] = useState()
    const [plotData,setPlotData] = useState()
    const svgMapRef = useRef()
    useEffect(() => {
        setTopodata(mdata.mapd)
        setVwAttr(mdata.viewbox)
        setFeatures(mdata.feature)
        setDefLabel(mdata.deftxtlabel)
        setPlotData(mdata.pdata)
    }, [])
    useEffect(() => {
        if (!topodata) return;
        let tooltip = d3.select(".containerM").append("div").attr("class", "toolTip");
        let colorScale = d3.scaleThreshold()
                           .domain(mdata.domain)
                           .range(mdata.colorscheme);
        let svgM = d3.select(svgMapRef.current).append("svg").attr("viewBox", vwAttr)//[640, 140, 120, 100])//attr('width', width + margin.left + margin.right).attr('height', height + margin.top + margin.bottom + 70)
        let projections = d3.geoMercator()
        let path = d3.geoPath().projection(projections)
        let fextract = '';
        if (features.length === 1) {
            fextract = topodata[features[0]];
        } else if (features.length === 2) {
            fextract = topodata[features[0]][features[1]];
        } else if (features.length === 3) {
            fextract = topodata[features[0]][features[1]][features[2]];
        } else if (features.length === 4) {
            fextract = topodata[features[0]][features[1]][features[2]][features[3]];
        }
        svgM.append("g")
            .selectAll("path")
            .data(feature(topodata, fextract).features)
            .join("path")
            .attr('fill', (d)=>{let txt = plotData.find((dt) => dt[mdata.pkey] === d['properties'][mdata.topokey]);
            if (!txt) {
                return "grey";
            } else {
                return colorScale(+txt[mdata.pplotkey]);
            }})
            .attr("d", path).on("mousemove click touchstart", function (eventb, d) {
                let txt = plotData.find((dt) => dt[mdata.pkey] === d['properties'][mdata.topokey]);
                let dispval = ''
                if(txt){
                     dispval = txt[mdata.pplotkey]
                }else{
                    dispval='NA'
                }
                tooltip
                    .style("left", d3.pointer(eventb, svgM)[0] + 20 + "px")
                    .style("top", d3.pointer(eventb, svgM)[1] + 1 + "px")
                    .style("display", "inline-block")
                    .html(mdata.disptext[0]+ dispval + mdata.disptext[1]+d['properties'][mdata.topokey] );
            }).on("mouseout touchend", function (d) { tooltip.style("display", "none"); })
            .append("title")
            .text(d => {
                if (defLabel.length === 1) {
                    return d[defLabel[0]] 
                }else if (defLabel.length === 2) {
                    return d[defLabel[0]][defLabel[1]]
                }else if (defLabel.length === 3) {
                    return d[defLabel[0]][defLabel[1]][defLabel[2]]
                }else if (defLabel.length === 4) {
                    return d[defLabel[0]][defLabel[1]][defLabel[2]][defLabel[3]]
                }
            })
        svgM.append("path")
            .datum(mesh(topodata, fextract, (a, b) => a !== b))
            .attr("fill", "none")
            .attr("stroke", "black")
            .attr("stroke-width", "0.1px")
            .attr("stroke-linejoin", "round")
            .attr("d", path);
    }, [topodata])
    if (!topodata) {
        return (
            <div>
                No map data
            </div>
        )
    } else {
        return (
            <div className='containerM'>
                <div ref={svgMapRef}>
                </div>
            </div>
        )
    }
}