import '../App.css'
import * as d3 from 'd3'
import React, { useState, useEffect, useRef } from 'react'
import topo from '../data/india_districts.json'
import tfr_india from '../data/tfr_india.csv'
import heatmapdata from '../data/heatmapdata.csv'
import cesnsus_2011 from '../data/india_census_2011.csv'
import bubbledata from '../data/bubbledata.csv'
import LineChart from './LineChart'
import CalendarHeatMap from './CalendarHeatMap'
import ChoroplethMap from './ChoroplethMap'
import BubbleChart  from './BubbleChart'
import { styled } from '@mui/material/styles'
import { createTheme, ThemeProvider } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

const useStyles = styled((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    slider: {
        track: {
            color: 'red'
        },
    },
    overrides: {
        MuiSlider: {
            thumb: {
                color: "yellow",
            },
            track: {
                color: 'red'
            },
            rail: {
                color: 'black'
            }
        }
    }
}));

const muiTheme = createTheme({
    palette: {
        primary: {
            main: '#3f50b5',
        },
    },
    overrides: {
        MuiSlider: {
            thumb: {
                color: "green",
                backgroundColor: 'green'
            },
            track: {
                height: 4,
                borderRadius: 2,
                color: "green"
            },
            rail: {
                color: 'green',
                backgroundColor: 'green',
            },
            mark: {
                color: 'green'

            },
            color: 'green',
            backgroundColor: 'green'
        }
    },
});


export default function HomeApp() {
    const classes = useStyles()
    const [tfrdata, setTfrdata] = useState([])
    const [valfr, setValfr] = useState(2020)
    const [hmapdata,setHmapdata] =useState([])
    const [m1Data, setM1Data] = useState()
    const [bbldata,setBbbldata] =useState([])
    useEffect(() => {
        d3.csv(tfr_india).then((data) => {
            setTfrdata({
                pdata: data,
                sliderxvalue: valfr,
                datauniqueid: 'Year',
                plotyvaluefield: 'TFR',
                plotxvaluefield: 'Year',
                disptext: ['Total Fertility Rate <br> Year: ', '<br> TFR: ', '<br> Country : '],
                additionaldisptext: ['Country'],
                viewbox: [-400, -140, 1800, 1000],
                xlabeltxt: 'Year',
                ylabeltxt: 'Total Fertility Rate',
                labeltxtcolor: 'green',
                labelfontsize: 40,
                xlabelpositiontuner: 25,
                ylabelpositiontuner: 20,
                defaultmarkradius: 5,
                defaultstrokewidth: 4,
                defaultmarkcolor: 'green',
                defaultstrokecolor: 'green',
                highmarkradius: 5,
                highmarkcolor: 'green',
                slidemarkercolor: 'darkblue',
                slidemarkerradius: 10,
                tickfontsize: 28,
                ticks: 6,
            })
            d3.csv(heatmapdata).then((data) => {
                setHmapdata({
                  pdata: data,
                  sliderxvalue: 2021,
                  datauniqueid: 'id',
                  plotvaluefield: 'score',
                  plotxvaluefield:'AgeGroup',
                  plotyvaluefield:'Date',
                  plotkey: ['India','US','China','Norway','Finland','Sweden'],
                  areacolors : ['green','white','pink','blue','purple','yellow'],
                  fillcolor: d3.schemePurples[9],
                  fillopacity:0.4,
                  disptext: ['Living Index Score <br/> Year : ', '<br/> Country : ', '<br/> Living Score : ','<br/> Cumulative for All Countries : '],
                  additionaldisptext: ['Country'],
                  viewbox: [-400, -80, 1800, 1000],//[-180, 0, 1600, 600],
                  xlabeltxt: 'Year',
                  ylabeltxt: 'Country Living Index',
                  labeltxtcolor: 'white',
                  labelfontsize: 40,
                  xlabelpositiontuner: 25,
                  ylabelpositiontuner: 80,
                  defaultmarkradius: 5,
                  defaultstrokewidth: 4,
                  defaultmarkcolor: 'green',
                  defaultstrokecolor: 'green',
                  highmarkradius: 5,
                  highmarkcolor: 'green',
                  slidemarkercolor: 'white',
                  slidemarkerradius: 10,
                  tickfontsize: 28,
                  ticks: 6,
                })
              })
              d3.csv(cesnsus_2011).then((data) => {
                setM1Data({
                  mapd: topo,
                  viewbox: [600, 140, 200, 100],
                  feature: ['objects', 'states'],
                  deftxtlabel: ['properties', 'st_nm'],
                  pdata: data,
                  pkey: 'State',
                  topokey: 'st_nm',
                  pplotkey: 'Sex Ratio - 2011',
                  disptext: ['Sex Ratio Based on 2011 Census <br> Females per 1000 Males: ', '<br> State : '],
                  domain: [900, 910, 920, 930, 940, 950, 980, 1000, 1090],
                  colorscheme: d3.schemeRdYlGn[9],
                })
              })
              d3.csv(bubbledata).then((data)=>{
                setBbbldata({
                  pdata:data,
                  plotyvaluefield: 'state',
                  plotxvaluefield:'AgeGroup',
                  plotvalue: 'score',
                  valuescalefactor: 5,
                  fillcolor: 'magenta',
                  fillopacity:0.4,
                  disptext: ['Living Index Score <br/> Year : ', '<br/> Country : ', '<br/> Living Score : ','<br/> Cumulative for All Countries : '],
                  additionaldisptext: ['Country'],
                  viewbox: [-400, -40, 1800, 1000],
                  xlabeltxt: 'Age Group',
                  ylabeltxt: 'State',
                  labeltxtcolor: 'white',
                  labelfontsize: 40,
                  xlabelpositiontuner: 45,
                  ylabelpositiontuner: 200,
                  defaultmarkradius: 5,
                  defaultstrokewidth: 4,
                  defaultmarkcolor: 'green',
                  defaultstrokecolor: 'green',
                  highmarkradius: 5,
                  highmarkcolor: 'green',
                  slidemarkercolor: 'white',
                  slidemarkerradius: 10,
                  tickfontsize: 28,
                  ticks: 6,
          
                })
              })
        })
    },[])
    if ( !tfrdata?.pdata || !hmapdata?.pdata || !m1Data?.pdata || !bbldata?.pdata) {
        return (<div>Loading </div>)
    }
    else if (tfrdata?.pdata && hmapdata?.pdata && m1Data?.pdata && bbldata?.pdata) {
  return (
    <div>                <div><p></p></div>
                
    <Container>
    <Grid >
  <Box color="text.primary" clone>
    <Grid item sm={12} xs={12}>
      <Paper style={{ backgroundColor: "white" }}>
        <Typography variant="h4" gutterBottom align="center" style={{ 'color': 'darkblue' }}>
          Visual Narrative Series
        </Typography>
        <p></p>
        <Typography variant="body1" gutterBottom align='left'  style={{ 'color': 'grey','marginLeft':'30px', 'textIndent':'40px'}}>
       Data is scattered all over the world and abundant. Utilizing the data to have cohesive narrative of the underlying details is part art, part science and part imagination.
       No narrative is complete. Every narrative has a form of bias. The narrative series attempted here will also have a bias either intentionally or unintentionally.
       Underlying data is the same. I attempt to bring various data points on the same topic and provide minimal narrative on those topics. Part visual, part definition and part my own narrative on the data.
       It is a fun attempt to collect the relevant data point from sources of some repute. Some maybe complete and some may be incomplete. Every data source will be disclosed in each of the series.
       One topic could have data from multiple sources and sometimes they may not be coherent. While i could attempt to make them coherent but that might end up in some unintentional alterations of the correct data.
       There are minimal alterations made to the data where needed like making a particular measure being named or labeled the same which does not change the underlying data.
       <p>

       </p>
       </Typography>
       <Typography variant="body1" gutterBottom align='left'  style={{ 'color': 'grey','marginLeft':'30px', 'textIndent':'40px'}}>
       All of these visualizations are done utlizing the d3js library. Simple and elegant visualization library. Mike Bostoc and his team has done wonderful contribution to the world of visualization.
       You can take a look at <a href="https://d3js.org/">D3JS Visualization library</a> and pay a visit to Mike Bostoc's Observable <a href="https://observablehq.com/@mbostock">@Mikebostoc</a>.
       <br/>Mostly we will keep to simple visualization forms to convey the story. I will stick to simple barcharts, Line charts and Choropleth Map charts libe the ones below. There will be some calendar view, bubble chart/scatter plot.
       While complex visualizations are made easy with d3js i will stick to simpler visualization forms which could be as effective. It is an evovling art as this is mostly done in spare time as and when i get curious with data, more like a sub-hobby.
       <br/>All these visualizations have hover-over popup display that may provide information for that specific data point.
       </Typography>
       
       <Grid container spacing={1}>


<Grid item sm={3} xs={6}>
       <LineChart pdata={tfrdata} />
       <Typography variant="body1" style={{ 'color': 'purple'}}>Simple Line Chart</Typography>
       </Grid>
       <Grid item sm={3} xs={6}>
       <CalendarHeatMap pdata={hmapdata}/>
       <Typography variant="body1" style={{ 'color': 'purple'}}>HeatMap Chart</Typography>
       </Grid>
       <Grid item sm={3} xs={6}>
       <ChoroplethMap mdata={m1Data}></ChoroplethMap><br/>
       <Typography variant="body1" style={{ 'color': 'purple'}}>Choropleth Chart</Typography>
       </Grid>
       <Grid item sm={3} xs={6}>
       <BubbleChart pdata={bbldata}></BubbleChart>
       <Typography variant="body1" style={{ 'color': 'purple'}}>Bubble Chart</Typography>
       </Grid>
       
         </Grid>
         <Typography variant="body1" gutterBottom align='left'  style={{ 'color': 'grey','marginLeft':'30px', 'textIndent':'40px'}}>
             There is a back story to this little sub-hobby. I was having a discussion with family about Bharat's (India) population, it's demographic dividends and how things are changing. 
             One of the assumptions was that population of Bharat would continue to rise but we also know from our own family circle and friend's circle that most had one kid and very rarely had two.
             We also got curious about various aid agencies and international leaders to local politicians talk about population control.
              I gathered the data and then looked at the data for reality and what was communicated.
              So i wanted to use simple yet powerful lightweight visualization library to plot the data. There was a gap between the narratives in the news and the world and what the data told us.
             Few things became obvious and that was the power of simple visualization with simple time series plot.
         </Typography>
         <Typography variant="body1" gutterBottom align='left'  style={{ 'color': 'grey','marginLeft':'30px', 'textIndent':'40px'}}>
             With that back-story that was the first visualization that came about for the population story of Bharat. Data came from UN projections and census data from Govt. of India.
             Other data i was interested was the power sector in India. It's a rather complicated story and data set. Data sources comes from variety of websites run by various ministries of power in India.
              How much India is consuming per day.What are the sources for electricity and what is the current potential? How financially sound is the India's power sector.
              Both these data sets Population and Power are important base for the future for any country so i was genuinely interested about India's story and find out what the reality is.
              <br/><br/>
         </Typography>
      </Paper>
      
    </Grid></Box> </Grid>
    
    </Container><p><br/><br/><br/><br/></p></div>
  )}
}
