import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'

export default function Barchart({ pdata }) {
    const svgBarRef = useRef()
    const wrapperRef = useRef()
    const data = pdata.pdata.sort((a, b) => { return b[pdata.plotvaluefield] - a[pdata.plotvaluefield] })
    let margin = { top: 10, right: 30, bottom: 30, left: 100 },
        width = 1000 - margin.left - margin.right,
        height = 800 - margin.top - margin.bottom;
    let slidemarker = pdata.sliderxvalue
    useEffect(() => {
        const svgB = d3.select(svgBarRef.current).select('svg').attr('viewBox', pdata.viewbox)
        let tooltip = d3.select(".containerB").append("div").attr("class", "toolTip");
        let bextent = d3.extent(data, (d) => {
            return +d[pdata.plotvaluefield]
        })
        let cardinal = d3.scaleBand().domain(data.map(function (d) { return d[pdata.plotkey] })).range([0, height]).padding(0.05)
        let valuescale = d3.scaleLinear().domain([0, bextent[1]]).range([0, width])
        const yAxis = d3.axisLeft(cardinal)
        const xAxis = d3.axisBottom(valuescale).tickFormat(d3.format('d'))
        svgB.select('.y-axis').style('transform', `translate(-20px)`).call(yAxis)
        svgB.select('.x-axis').style('transform', `translate(0px,${height + 10}px)`).call(xAxis.ticks(pdata.ticks))
        svgB.selectAll(".x-axis>.tick>text")
            .each(function (d, i) {
                d3.select(this).style("font-size", pdata.tickfontsize);
            })
        svgB.selectAll(".y-axis>.tick>text")
            .each(function (d, i) {
                d3.select(this).style("font-size", pdata.tickfontsize);
            })
        svgB.select('.y-label').append('text').attr('transform', `rotate(-90)`)
            .attr('y', 0 - margin.left - pdata.ylabelpositiontuner)
            .attr('x', 0 - height / 2)
            .attr('dy', '0.5em')
            .attr('text-anchor', 'middle')
            .text(pdata.ylabeltxt).style('font-size', pdata.labelfontsize).style('fill', pdata.labeltxtcolor)
        svgB.select('.x-label').append('text').attr('transform', `translate(${(width / 2)},${height + pdata.xlabelpositiontuner + margin.bottom + 30})`)
            .attr('text-anchor', 'middle')
            .text(pdata.xlabeltxt).style('font-size', pdata.labelfontsize).style('fill', pdata.labeltxtcolor)
        if (data) {
            svgB.selectAll('rect').remove()
            svgB.selectAll('rect')
                .data(data, key => { return data[pdata.plotkey] })
                .enter()
                .append('rect')
                .attr("x", function (d) { return 0; })
                .attr("width", function (d) { return valuescale(+d[pdata.plotvaluefield]) })
                .attr("y", function (d) { return cardinal(d[pdata.plotkey]) })
                .attr("height", cardinal.bandwidth())
                .attr("fill", pdata.fillcolor)
                .attr("opacity",pdata.fillopacity)
                .on("mousemove click touchstart", function (eventb, d) {
                    tooltip
                        .style("left", d3.pointer(eventb, svgB)[0] + 20 + "px")
                        .style("top", d3.pointer(eventb, svgB)[1] + 1 + "px")
                        .style("display", "inline-block")
                        .html(pdata.disptext[0] + (d[pdata.plotvaluefield]) + pdata.disptext[1] + d[pdata.plotkey]);
                }).on("mouseout touchend", function (d) { tooltip.style("display", "none"); })
        }
    }, [pdata])

    if (!pdata) {
        return (
            <div>
                No Data
            </div>
        )
    } else {
        return (<div className='containerB' ref={wrapperRef}>
            <div ref={svgBarRef}>
                <svg>
                    <g className='x-axis' />
                    <g className='y-axis' />
                    <g className='x-label' />
                    <g className='y-label' />
                </svg>
            </div>
        </div>)
    }
}
