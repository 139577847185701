import '../App.css'
import * as d3 from 'd3'
import React, { useState, useEffect, useRef } from 'react'
import electricitydailydata1 from '../data/electricity_consumption_daily_202205161022.csv'
import electricityefficiency from '../data/India_power_Efficiency.csv'
import topo from '../data/india_districts.json'
import installedcap from '../data/installed_capacity_statewise.csv'
import installedcapall from '../data/installed_capacity_allindia.csv'
import CalendarView from './CalendarView'
import LineChart from './LineChart'
import ChoroplethMap from './ChoroplethMap'
import Barchart from './BarChart'
import { styled } from '@mui/material/styles'
import { createTheme, ThemeProvider } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'



const useStyles = styled((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  slider: {
    track: {
      color: 'red'
    },
  },
  overrides: {
    MuiSlider: {
      thumb: {
        color: "yellow",
      },
      track: {
        color: 'red'
      },
      rail: {
        color: 'black'
      }
    }
  }
}));
function ElecApp() {
  const [cvdata1, setCvdata1] = useState([])
  const [p1data1, setp1Data1] = useState([])
  const [cp1data1, setcp1Data1] = useState([])
  const [instelect, setinstelect] = useState([])
  const [hcelect, sethcelect] = useState([])
  const [reelect, setreelect] = useState([])
  const [nuelect, setnuelect] = useState([])
  const [coelect, setcoelect] = useState([])
  const [hyelect, sethyelect] = useState([])
  const [eff, seteff] = useState([])
  const [effatc, seteffatc] = useState([])
  const [bData, setbData] = useState()
  const [elecsource, setelecsource] = useState()
  const [countrytotal, setcountrytotal] = useState([])
  const classes = useStyles()


  useEffect(() => {
    let plotdata = []
 
    d3.csv(electricitydailydata1).then((data) => {
      let filtereddata = []
      filtereddata = data.filter(d => d.States === 'ALL INDIA TOTAL')
      plotdata = filtereddata.map((d) => {
        let dateParts = d.Date.split('-')
        let newDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
        return ({
          date: newDate,
          state: d.States,
          consumption: +d.Consumption
        })
      })
      let total_cons_by_state = ''
      let state_name = ''
      let newgrouped = d3.flatGroup(data, d => d.States).map((d) => {
        total_cons_by_state = d[1].reduce((a, b) => {
          const consumption = parseFloat(b.Consumption);
          return isNaN(consumption) ? a : a + consumption;
          //return a + parseFloat(b.Consumption)
        }, 0.0)
        state_name = d[0]
        if (state_name === 'UP') {
          state_name = 'Uttar Pradesh'
        } else if (state_name === 'HP') {
          state_name = 'Himachal Pradesh'
        } else if (state_name === 'J&K') {
          state_name = 'Jammu and Kashmir'
        } else if (state_name === 'DD') {
          state_name = 'Dadra and Nagar Haveli and Daman and Diu'
        } else if (state_name === 'MP') {
          state_name = 'Madhya Pradesh'
        } else if (state_name === 'Pondy') {
          state_name = 'Puducherry'
        }
        return { state_name: state_name, total_cons_by_state: total_cons_by_state }

      })
      //console.log(newgrouped)
      setcp1Data1({
        mapd: topo,
        viewbox: [640, 140, 120, 100],
        feature: ['objects', 'states'],
        deftxtlabel: ['properties', 'st_nm'],
        pdata: newgrouped,
        pkey: 'state_name',
        topokey: 'st_nm',
        pplotkey: 'total_cons_by_state',
        disptext: ['Energy Consumption in MwHr <br> Total Energy : ', '<br/>State : '],
        domain: [ 5000, 50000, 100000, 140000, 180000, 290000, 400000, 500000],
        colorscheme: d3.schemeYlGnBu[9],
      })
      //console.log(Object.entries(plotdata).sort((a,b)=> b[1].date -a[1].date ))
      let newplotdata = plotdata.sort((a, b) => a.date - b.date)
      setp1Data1({
        pdata: plotdata,
        //sliderxvalue: val,
        datauniqueid: 'date',
        plotyvaluefield: 'consumption',
        plotxvaluefield: 'date',
        plotxvaluedatefield: 'Y',
        disptext: ['Energy Consumption in MU <br> Date: ', '<br> Consumption: ', '<br>'],
        additionaldisptext: ['state'],
        viewbox: [-300, -30, 1300, 900],
        xlabeltxt: 'date',
        ylabeltxt: 'consumption in million units',
        labeltxtcolor: 'grey',
        labelfontsize: 40,
        xlabelpositiontuner: 45,
        ylabelpositiontuner: 100,
        defaultmarkradius: 3,
        defaultstrokewidth: 3,
        defaultmarkcolor: 'lightblue',
        defaultstrokecolor: 'grey',
        highmarkradius: 10,
        highmarkcolor: 'orange',
        slidemarkercolor: 'white',
        slidemarkerradius: 10,
        tickfontsize: 28,
        ticks: 5,
        xtickformat: 'date',
      })
      setCvdata1({
        pdata: plotdata,
        startyear: 2021,
        endyear: 2023,
        datauniqueid: 'date',
        plotvaluefield: 'consumption',
        plotyvaluefield: 'date',
        plotkey: 'date',
        domain: [2500, 3000, 3300, 3500, 3800, 4000, 4100, 4200, 4400],
        colorscheme: d3.schemeBlues[9],
        defaultcolor: 'grey',
        fillopacity: 0.4,
        disptext: ['Daily Electricity Consumption <br/> Date : ', '<br/> Million Units : ', '<br/> Living Score : ', '<br/> Cumulative for All Countries : '],
        additionaldisptext: ['Country'],
        viewbox: [-60, -30, 400, 1170],//[-180, 0, 1600, 600],[-60, -30, 400, 780]
      })
    })
    
    //d3.json('https://cea.nic.in/api/installed_capacity_statewise.php')
    d3.csv(installedcap)
      .then((data) => {
        let installedelectricity = data.filter(d => d.month === 'Dec-2023')
        //console.log(installedelectricity)
        let totaldata = installedelectricity.map((d) => {
          if (d.state === 'Chhatisgarh') {
            return { state: 'Chhattisgarh', total: parseInt(d.grand_total) }
          } else {
            return { state: d.state, total: parseInt(d.grand_total) }
          }
        })
        let hcdata = installedelectricity.map((d) => {
          if (d.state === 'Chhatisgarh') {
            return { state: 'Chhattisgarh', hc: parseInt(d.gas) + parseInt(d.diesel) }
          } else {
            return { state: d.state, hc: parseInt(d.gas) + parseInt(d.diesel) }
          }
        })
        let codata = installedelectricity.map((d) => {
          if (d.state === 'Chhatisgarh') {
            return { state: 'Chhattisgarh', co: parseInt(d.coal) }
          } else {
            return { state: d.state, co: parseInt(d.coal) }
          }
        })
        let nudata = installedelectricity.map((d) => {
          if (d.state === 'Chhatisgarh') {
            return { state: 'Chhattisgarh', nu: parseInt(d.nuclear) }
          } else {
            return { state: d.state, nu: parseInt(d.nuclear) }
          }
        })
        let hydata = installedelectricity.map((d) => {
          if (d.state === 'Chhatisgarh') {
            return { state: 'Chhattisgarh', hy: parseInt(d.hydro) }
          } else {
            return { state: d.state, hy: parseInt(d.hydro) }
          }
        })

        let redata = installedelectricity.map((d) => {
          if (d.state === 'Chhatisgarh') {
            return { state: 'Chhattisgarh', re: parseInt(d.res) }
          } else {
            return { state: d.state, re: parseInt(d.res) }
          }
        })

        let summeddata = installedelectricity.reduce((a, b) => {
          return a = a + parseInt(b.grand_total)
        }, 0)
        //      console.log(totaldata.reduce((a,b)=>{ return a= a+ b.total},0))

        setbData({
          pdata: totaldata,
          sliderxvalue: 2021,
          datauniqueid: 'state',
          plotvaluefield: 'total',
          plotkey: 'state',
          fillcolor: 'blue',
          fillopacity: 0.5,
          disptext: ['Installed Electricity Capacity : ', ' MWHr<br> State: ', '<br> Country : '],
          additionaldisptext: ['Country'],
          viewbox: [-450, -330, 1400, 1300],//[-180, 0, 1600, 600],
          xlabeltxt: 'Installed electricity capacity in MWHr',
          ylabeltxt: 'State',
          labeltxtcolor: 'blue',
          labelfontsize: 40,
          xlabelpositiontuner: 25,
          ylabelpositiontuner: 240,
          defaultmarkradius: 5,
          defaultstrokewidth: 4,
          defaultmarkcolor: 'green',
          defaultstrokecolor: 'green',
          highmarkradius: 5,
          highmarkcolor: 'green',
          slidemarkercolor: 'white',
          slidemarkerradius: 10,
          tickfontsize: 24,
          ticks: 6,
        })

        // console.log(hcdata)
        setinstelect({
          mapd: topo,
          viewbox: [640, 140, 120, 100],
          feature: ['objects', 'states'],
          deftxtlabel: ['properties', 'st_nm'],
          pdata: totaldata,
          pkey: 'state',
          topokey: 'st_nm',
          pplotkey: 'total',
          disptext: ['Total Installed Capacity  : ', '<br/>State : '],
          domain: [3000, 7000, 12000, 18000, 22000, 26000, 30000, 36000, 40000],
          colorscheme: d3.schemeGnBu[9],
        })
        sethcelect({
          mapd: topo,
          viewbox: [640, 140, 120, 100],
          feature: ['objects', 'states'],
          deftxtlabel: ['properties', 'st_nm'],
          pdata: hcdata,
          pkey: 'state',
          topokey: 'st_nm',
          pplotkey: 'hc',
          disptext: ['HydroCarbon based Installed Capacity  : ', '<br/>State : '],
          domain: [1000, 2000, 3000, 5000, 7000, 10000, 15000, 18000, 22000],
          colorscheme: d3.schemeOrRd[9],
        })
        setcoelect({
          mapd: topo,
          viewbox: [640, 140, 120, 100],
          feature: ['objects', 'states'],
          deftxtlabel: ['properties', 'st_nm'],
          pdata: codata,
          pkey: 'state',
          topokey: 'st_nm',
          pplotkey: 'co',
          disptext: ['Coal based Installed Capacity  : ', '<br/>State : '],
          domain: [1000, 2000, 3000, 5000, 7000, 10000, 15000, 18000, 22000],
          colorscheme: d3.schemeGreys[9],
        })
        setreelect({
          mapd: topo,
          viewbox: [640, 140, 120, 100],
          feature: ['objects', 'states'],
          deftxtlabel: ['properties', 'st_nm'],
          pdata: redata,
          pkey: 'state',
          topokey: 'st_nm',
          pplotkey: 're',
          disptext: ['Renewable based Installed Capacity  : ', '<br/>State : '],
          domain: [1000, 2000, 3000, 5000, 7000, 10000, 15000, 18000, 22000],
          colorscheme: d3.schemeBuGn[9],
        })
        setnuelect({
          mapd: topo,
          viewbox: [640, 140, 120, 100],
          feature: ['objects', 'states'],
          deftxtlabel: ['properties', 'st_nm'],
          pdata: nudata,
          pkey: 'state',
          topokey: 'st_nm',
          pplotkey: 'nu',
          disptext: ['Nuclear based Installed Capacity  : ', '<br/>State : '],
          domain: [100, 300, 500, 1000, 1500, 2000, 2500, 3000, 5000],
          colorscheme: d3.schemePurples[9],
        })
        sethyelect({
          mapd: topo,
          viewbox: [640, 140, 120, 100],
          feature: ['objects', 'states'],
          deftxtlabel: ['properties', 'st_nm'],
          pdata: hydata,
          pkey: 'state',
          topokey: 'st_nm',
          pplotkey: 'hy',
          disptext: ['HydelEnergy based Installed Capacity  : ', '<br/>State : '],
          domain: [1000, 2000, 3000, 5000, 7000, 10000, 15000, 18000, 22000],
          colorscheme: d3.schemeBlues[9],
        })

      })
    //d3.json('https://cea.nic.in/api/installed_capacity_allindia.php')
    d3.csv(installedcapall)
    .then((data) => {
      let totalinstalled = data.filter((d) => d.month === 'Dec-2023')
      let newtotalinstalled = totalinstalled[0]
      newtotalinstalled.hydrocarbon = parseInt(newtotalinstalled.diesel) + parseInt(newtotalinstalled.gas)
      newtotalinstalled.hydro = parseInt(newtotalinstalled.hydro)
      newtotalinstalled.res = parseInt(newtotalinstalled.res)
      newtotalinstalled.nuclear = parseInt(newtotalinstalled.nuclear)
      newtotalinstalled.coal = parseInt(newtotalinstalled.coal)
      newtotalinstalled.grand_total = parseInt(newtotalinstalled.grand_total)
      newtotalinstalled.hcperc = (((parseInt(newtotalinstalled.diesel) + parseInt(newtotalinstalled.gas)) / parseInt(newtotalinstalled.grand_total)) * 100).toFixed(2)
      newtotalinstalled.hydroperc = ((newtotalinstalled.hydro / newtotalinstalled.grand_total) * 100).toFixed(2)
      newtotalinstalled.coalperc = ((newtotalinstalled.coal / newtotalinstalled.grand_total) * 100).toFixed(2)
      newtotalinstalled.nuclearperc = ((newtotalinstalled.nuclear / newtotalinstalled.grand_total) * 100).toFixed(2)
      newtotalinstalled.resperc = ((newtotalinstalled.res / newtotalinstalled.grand_total) * 100).toFixed(2)
      //     console.log(newtotalinstalled)
      setcountrytotal(newtotalinstalled)

      let newdata = []
      Object.keys(newtotalinstalled).forEach((d) => {
        if (d === 'hydrocarbon' || d === 'hydro' || d === 'nuclear' || d === 'res' || d === 'coal') {
          newdata.push({ source: d, value: newtotalinstalled[d] })
        }

      })
      setelecsource({
        pdata: newdata,
        sliderxvalue: 2021,
        datauniqueid: 'source',
        plotvaluefield: 'value',
        plotkey: 'source',
        fillcolor: 'blue',
        fillopacity: 0.5,
        disptext: ['Installed Electricity Capacity : ', ' MWHr<br> Source : ', '<br> Country : '],
        additionaldisptext: ['Country'],
        viewbox: [-360, -330, 1400, 1300],//[-180, 0, 1600, 600],
        xlabeltxt: 'Installed electricity capacity in MWHr',
        ylabeltxt: 'Source',
        labeltxtcolor: 'blue',
        labelfontsize: 40,
        xlabelpositiontuner: 25,
        ylabelpositiontuner: 180,
        defaultmarkradius: 5,
        defaultstrokewidth: 4,
        defaultmarkcolor: 'green',
        defaultstrokecolor: 'green',
        highmarkradius: 5,
        highmarkcolor: 'green',
        slidemarkercolor: 'white',
        slidemarkerradius: 10,
        tickfontsize: 24,
        ticks: 6,
      })
    })

    d3.csv(electricityefficiency).then((data) => {
      let acs_arr = data.filter((d) => d.Measure === 'ACS - ARR Gap (Rs./ Unit)')
      let atc = data.filter((d) => d.Measure === 'AT&C Loss')
      seteff({
        mapd: topo,
        viewbox: [640, 140, 120, 100],
        feature: ['objects', 'states'],
        deftxtlabel: ['properties', 'st_nm'],
        pdata: acs_arr,
        pkey: 'State',
        topokey: 'st_nm',
        pplotkey: 'Value',
        disptext: ['ACS ARR Gap  : ', ' Per unit <br/>State : '],
        domain: [-0.2, -0.1, 0, 1, 2, 3, 5, 7, 10],
        colorscheme: d3.schemePuRd[9],
      })
      seteffatc({
        mapd: topo,
        viewbox: [640, 140, 120, 100],
        feature: ['objects', 'states'],
        deftxtlabel: ['properties', 'st_nm'],
        pdata: atc,
        pkey: 'State',
        topokey: 'st_nm',
        pplotkey: 'Value',
        disptext: ['AT&C Loss  : ', ' % <br/>State : '],
        domain: [10, 12, 15, 18, 21, 25, 29, 34, 39],
        colorscheme: d3.schemeOranges[9],
      })
    })

  }, [])
  if (!p1data1?.pdata || !cvdata1?.pdata || !cp1data1.pdata || !instelect.pdata || !hcelect.pdata || !coelect.pdata || !nuelect.pdata || !hyelect.pdata || !countrytotal || !bData?.pdata || !elecsource?.pdata || !eff?.pdata) {
    return (<div>Loading </div>)
  }
  else if (p1data1?.pdata && cvdata1?.pdata && cp1data1?.pdata && instelect?.pdata && hcelect?.pdata && coelect?.pdata && nuelect?.pdata && hyelect?.pdata && countrytotal && bData?.pdata && elecsource?.pdata && eff?.pdata) {
    return (
      <div className="App">
        <div className={classes.root}>


          <p></p>
          <Container >

                <Grid >
              <Box color="text.primary" clone>
                <Grid item sm={12} xs={12}>
                  <Paper style={{ backgroundColor: "white" }}>
                    <Typography variant="h4" gutterBottom align="center" style={{ 'color': 'darkblue' }}>
                      Overview of Electricity in India
                    </Typography>
                    <Typography variant="body1" gutterBottom align='left'  style={{ 'color': 'grey','marginLeft':'30px', 'textIndent':'40px'}}>
                    Energy is one of the importnat factors for any nation. Availability of affordable energy is key to the development
                      of the country as well as the security of the nation.
                       Here in this visual story series we will look at various aspects
                      of electricity based on data provided by the Govt. of India. 
                      There are daily reports, weekly reports and dashboards published
                      by various ministries. Here we combine some important vital data. Visualizations are split into three sections starting with installed power generating capacity. Second is Efficiency and third one is about electricity consumption.
                      </Typography>
                      <Typography variant="body1" gutterBottom align='left'  style={{ 'color': 'grey','marginLeft':'30px', 'textIndent':'40px'}}>
                      One of them is the electricity consumption data based on weekly 
                      report. Another data set provides installed electricity generation capacity by various sources and one about efficiency of the 
                      electricity sector. These are considered to be vital parameters among others.
                      Daily consumption data will provide insights into the demand/growth over the period indicating seasons and economic activity.
                      Installed capacity provides an overview of overall generating capacity giving insight into how much industrial activity potential there is. 
                      The efficiency data measures like AT&C loss and ACS ARR gap indicates the financial health of the sector providing insights if it will be 
                      attractive for private investors. 
                      <br/>Data for Installed capacity can be found from <a href="https://cea.nic.in/api-for-central-electricity-authority-data/?lang=en">Central Electricity Authority of India</a>
                      <br/>Data for Daily electricity and other plant load factors cane be found from <a href="https://posoco.in/reports/weekly-reports/">POSOCO</a>.
                      <br/>Data for Power Efficiency can be found from <a href="https://uday.gov.in/home.php">UDAY</a> Dashboard website.
                      <br/>All these visualizations have hover-over popup display that may provide information for that specific data point.
                    </Typography>
                  </Paper>
                </Grid></Box> </Grid>
                
                <Grid >
              <Box color="text.primary" clone>
                <Grid item sm={12} xs={12}>
                  <Paper style={{ backgroundColor: "white" }}>
                    <Typography variant="h5" gutterBottom align="center" style={{ 'color': 'darkblue' }}>
                      Installed Electricity Capacity
                    </Typography>
                  </Paper>
                </Grid></Box> </Grid>            

            <Typography variant="h5" component="h5" align="center" style={{ 'color': 'purple' }}>
              Total Installed Capacity {countrytotal.grand_total} MWHr
            </Typography>
            <Grid container spacing={1}>
              <Grid item sm={6} xs={12} >
                <Card className={classes.root}>
                  <CardContent style={{ backgroundColor: "white" }}>
                    <Typography variant="h6" component="h6" align="center" style={{ 'color': 'purple' }}>
                      Installed Electricity Capacity by State
                    </Typography>
                    <Barchart pdata={bData}></Barchart>
                    <Typography variant="body1" gutterBottom align='left'  style={{ 'color': 'grey','marginLeft':'30px', 'textIndent':'40px'}}>
                    <b style={{ 'color': 'blue' }}> Gujarat has the largest installed capacity </b>. Interesting to note is that  about <b style={{ 'color': 'blue' }}>30%</b> of the total generating capacity is located within
                    <b style={{ 'color': 'blue' }}>three states of Gujarat, Maharashtra and Tamilnadu</b> even though they are not the most populous state.<br/>
                    Another <b style={{ 'color': 'blue' }}>18%</b> comes from other southern states of <b style={{ 'color': 'blue' }}>Karnataka, Andhra Pradesh and Telangana</b>.
                   <br/> <b style={{ 'color': 'blue' }}>Uttar Pradesh and Bihar</b> being the most populous states containing <b style={{ 'color': 'blue' }}>25% of India's population</b>  only have about less than <b style={{ 'color': 'blue' }}>10%</b> of the generating capacity.
<br/><br/>
                    </Typography>
                  </CardContent></Card>
              </Grid>
              <Grid item sm={6} xs={12} >
                <Card className={classes.root}>
                  <CardContent style={{ backgroundColor: "white" }}>
                    <Typography variant="h6" component="h6" align="center" style={{ 'color': 'purple' }}>
                      Installed Electricity Capacity by Source
                    </Typography>
                    <Barchart pdata={elecsource}></Barchart>
                    <Typography variant="body1" gutterBottom align='left'  style={{ 'color': 'grey','marginLeft':'30px', 'textIndent':'40px'}}>
                      There is a total installed generating capacity of 401010 MWHr as of April 2022. Installed capacity does not equate to daily output but rather how much potential peak output there could be.
                      India's approach towards electricity keeping in mind of energy security is evident in the mix of energy sources.
                      <br/><b style={{ 'color': 'blue' }}>Coal</b> powered thermal power plants account for half of the generating capacity around <b style={{ 'color': 'blue' }}>51%</b>. 
                      <br/><b style={{ 'color': 'blue' }}>Renewables (Solar,Wind)</b> account for about <b style={{ 'color': 'blue' }}>29%</b>.
                      <br/><b style={{ 'color': 'blue' }}>Hydel</b> power plants about <b style={{ 'color': 'blue' }}>12.3%</b> and 
                      <br/><b style={{ 'color': 'blue' }}>Nuclear</b> about <b style={{ 'color': 'blue' }}>1.7%</b> and <b style={{ 'color': 'blue' }}>Hydro carbons</b> at around <b style={{ 'color': 'blue' }}>6%</b>.
                    </Typography>
                  </CardContent></Card>
              </Grid>
              <Grid item sm={6} xs={12} >
                <Card className={classes.root}>
                  <CardContent style={{ backgroundColor: "white" }}>
                    <Typography variant="h6" component="h6" align="center" style={{ 'color': 'purple' }}>
                      Electricity from all Sources by State
                    </Typography>
                    <br /><br /><br /><br />
                    <ChoroplethMap mdata={instelect}></ChoroplethMap>
                    <Typography variant="body1" gutterBottom align='left'  style={{ 'color': 'grey','marginLeft':'30px', 'textIndent':'40px'}}>
                      Same information as in the above bar chart. Here same data is visualized in the form of geographical map. Dark Blue hues to Pale Green indicate 
                      installed capacity from higher to lower.<br/><br/>
                    </Typography>
                  </CardContent></Card>
              </Grid>
              <Grid item sm={6} xs={12} >
                <Card className={classes.root}>
                  <CardContent style={{ backgroundColor: "white" }}>
                    <Typography variant="h6" component="h6" align="center" style={{ 'color': 'purple' }}>
                      Electricity Capacity from Diesel and Gas
                    </Typography>
                    <Typography variant="h6" component="h6" align="center" style={{ 'color': 'purple' }}>
                      Total  {countrytotal.hydrocarbon} MWHr {countrytotal.hcperc}%
                    </Typography>
                    <br /><br />
                    <ChoroplethMap mdata={hcelect}></ChoroplethMap>
                    <Typography variant="body1" gutterBottom align='left'  style={{ 'color': 'grey','marginLeft':'30px', 'textIndent':'40px'}}>
                      Installed capacity of Gas and Diesel powered plants data is visualized in the form of geographical map. Dark Red hues to Pale Orange hues indicate 
                      installed capacity of Gas and Diesel based power plants from higher to lower.
                    </Typography>
                  </CardContent></Card>
              </Grid>
              <Grid item sm={6} xs={12} >
                <Card className={classes.root}>
                  <CardContent style={{ backgroundColor: "white" }}>
                    <Typography variant="h6" component="h6" align="center" style={{ 'color': 'purple' }}>
                      Electricity Capacity from Coal
                    </Typography>
                    <Typography variant="h6" component="h6" align="center" style={{ 'color': 'purple' }}>
                      Total  {countrytotal.coal} MWHr {countrytotal.coalperc}%
                    </Typography>
                    <br /><br />
                    <ChoroplethMap mdata={coelect}></ChoroplethMap>
                    <Typography variant="body1" gutterBottom align='left'  style={{ 'color': 'grey','marginLeft':'30px', 'textIndent':'40px'}}>
                      Coal based power plants account for about 50% of the total installe capacity for generating electricity.
                      Uttar Pradesh, Maharashtra and Madhya Pradesh are top three states with coal based power plants. From black to pale grey indicate the generating capacity from coal higher to lower.
                    <br/><br/><br/><br/><br/><br/><br/>
                    </Typography>
                  </CardContent></Card>
              </Grid>
              <Grid item sm={6} xs={12} >
                <Card className={classes.root}>
                  <CardContent style={{ backgroundColor: "white" }}>
                    <Typography variant="h6" component="h6" align="center" style={{ 'color': 'purple' }}>
                      Electricity Capacity from Renewables
                    </Typography>
                    <Typography variant="h6" component="h6" align="center" style={{ 'color': 'purple' }}>
                      Total  {countrytotal.res} MWHr {countrytotal.resperc}%
                    </Typography>
                    <br /><br />
                    <ChoroplethMap mdata={reelect}></ChoroplethMap>
                    <Typography variant="body1" gutterBottom align='left'  style={{ 'color': 'grey','marginLeft':'30px', 'textIndent':'40px'}}>
                      Renewables constitute about 28% of installed capacity. Unlike other sources utilization factor for renewables is comparitively lower as evidenced from <a href="https://www.indiabudget.gov.in/economicsurvey/">economic survey 2022</a> Pg 310.
                      Renewables while constituting about <b style={{ 'color': 'blue' }}>22% of installed capacity during the economic survey only produced 10% of the electricity </b> during the survey period. It is one of the drawbacks of renewables when it comes to capacity vs availability and generation.
                      <b style={{ 'color': 'blue' }}>Rajasthan, Gujarat, Karnataka and Tamilnadu</b> lead in the installed capacity of renewables. While renewables include wind and solar, in recent years solar has been predominantly lead to increase in the installed capacity.


                    </Typography>
                  </CardContent></Card>
              </Grid>



<Grid item sm={6} xs={12} >
                <Card className={classes.root}>
                  <CardContent style={{ backgroundColor: "white" }}>
                    <Typography variant="h6" component="h6" align="center" style={{ 'color': 'purple' }}>
                      Electricity Capacity from Nuclear
                    </Typography>
                    <Typography variant="h6" component="h6" align="center" style={{ 'color': 'purple' }}>
                      Total  {countrytotal.nuclear} MWHr {countrytotal.nuclearperc}%
                    </Typography>
                    <br /><br />
                    <ChoroplethMap mdata={nuelect}></ChoroplethMap>
                    <Typography variant="body1" gutterBottom align='left'  style={{ 'color': 'grey','marginLeft':'30px', 'textIndent':'40px'}}>
                      Nuclear energy while looks promising as it looked promising for the last 4 decades <b style={{ 'color': 'blue' }}>constitutes less than 2% of generating capacity</b>.
                      While new power plants are in the pipeline and India's thress stage nuclear power program in place has huge cost overruns and Thorium based reactor still remains a dream.
                      Tamilnadu, Maharashtra, Rajasthan, Karnataka, Gujarat and Uttarpradesh are the only six states that have nuclear power stations.

                    </Typography>
                  </CardContent></Card>
              </Grid>
              <Grid item sm={6} xs={12} >
                <Card className={classes.root}>
                  <CardContent style={{ backgroundColor: "white" }}>
                    <Typography variant="h6" component="h6" align="center" style={{ 'color': 'purple' }}>
                      Electricity Capacity from Hydro
                    </Typography>
                    <Typography variant="h6" component="h6" align="center" style={{ 'color': 'purple' }}>
                      Total  {countrytotal.hydro} MWHr {countrytotal.hydroperc}%
                    </Typography>
                    <br /><br />
                    <ChoroplethMap mdata={hyelect}></ChoroplethMap>
                    <Typography variant="body1" gutterBottom align='left'  style={{ 'color': 'grey','marginLeft':'30px', 'textIndent':'40px'}}>
                      Hydel based power generation constitutes about 12% of the installed generation capacity. 
                      Since independence few mega projects were unveiled and they have very modest growth if any in the recent years.
                      Hydel has the added complexity of certain geographic terrains and restrictions. 
                      As is evidenced from the visualization all the states with hilly mountaneous area all have hydel power stations.
                      <br/><br/>

                    </Typography>
                  </CardContent></Card>
              </Grid>




            </Grid>
            <br /> 
            <Grid >
              <Box color="text.primary" clone>
                <Grid item sm={12} xs={12}>
                  <Paper style={{ backgroundColor: "white" }}>
                    <Typography variant="h5" gutterBottom align="center" style={{ 'color': 'darkblue' }}>
                      Electricity Efficiency Metrics
                    </Typography>
                  </Paper>
                </Grid></Box> </Grid>
            <Grid container spacing={1}>
            <Grid item sm={6} xs={12} >
                <Card className={classes.root}>
                  <CardContent style={{ backgroundColor: "white" }}>
                    <Typography variant="h6" component="h6" align="center" style={{ 'color': 'purple' }}>
                      Electricity Financial Efficiency by State
                    </Typography>
                    <Typography variant="h6" component="h6" align="center" style={{ 'color': 'purple' }}>
                      ACS ARR GAP
                    </Typography>
                    <br /><br />
                    <ChoroplethMap mdata={eff}></ChoroplethMap>
                    <Typography variant="body1" gutterBottom align='left'  style={{ 'color': 'grey','marginLeft':'30px', 'textIndent':'40px'}}>
                      ACS ARR gap is one of the most important metric when it comes to financial health of power sector.
                      <br/><b style={{ 'color': 'blue' }}>ACS stands for Average Cost of Supply per unit</b>
                      <br/><b style={{ 'color': 'blue' }}>ARR Stands for Average Revenue Realized per unit</b>
                      <br/> If this factor is positive and big that indicates loss for the Power utility.
                      <br/> If this factor is negative it indicates Power utilities generating positive Revenue.
                      <br/> <b style={{ 'color': 'blue' }}>Gujarat, Karnataka, Punjab, Uttarakhand and Himachal Pradesh</b> are the only states with some <b style={{ 'color': 'blue' }}>positive </b>little revenue yielding states.
                      Power is mostly a state subject and it is entirely dependent on the condition and policies of the state governments to the most extent.
                      <br/><b style={{ 'color': 'blue' }}>Badly </b> performing states/UT are <b style={{ 'color': 'blue' }}>Sikkim (small hilly state),Jammu & Kashmir, Tamilnadu, Manipur, Madhya pradesh and Telangana</b>.
                      This factor could incur burden on the utilities and incur them debt preventing them from taking up future projects.
                    </Typography>
                  </CardContent></Card>
              </Grid>
              <Grid item sm={6} xs={12} >
                <Card className={classes.root}>
                  <CardContent style={{ backgroundColor: "white" }}>
                    <Typography variant="h6" component="h6" align="center" style={{ 'color': 'purple' }}>
                      Electricity ATC Loss by State
                    </Typography>
                    <Typography variant="h6" component="h6" align="center" style={{ 'color': 'purple' }}>
                      AT&C Losses
                    </Typography>
                    <br /><br />
                    <ChoroplethMap mdata={effatc}></ChoroplethMap>
                    <Typography variant="body1" gutterBottom align='left'  style={{ 'color': 'grey','marginLeft':'30px', 'textIndent':'40px'}}>
                    AT&C Losses stand for Aggregate Technical & Commercial Losses.
                    <br/>Basic formula to calculate AT&C Loss is 
                    <br/><b style={{ 'color': 'blue' }}>AT&C Loss = (Energy input – Energy billed) * 100 / Energy input</b> .
                    <br/>For India guidelines to calculate AT&C loss is published <a href="https://cea.nic.in/wp-content/uploads/2020/04/guidelines_atc_loss.pdf">here by CEA</a> (Central Electricity Authority).
                    <br/>If this metric is higher that indicates a loss either in the form of unbilled units in the form of subsidized supply or theft or improper billing and includes transmission power losses as well.
                    <br/> Lower this metric the better it is for the financial health for the system.
                    <b style={{ 'color': 'blue' }}>Jharkhand, Bihar, Meghalaya, Sikkim, Jammu and Kashmir, Uttarpradesh and Madhyapradesh are badly performing states</b>.
                    <br/><br/><br/>
                    </Typography>
                  </CardContent></Card>
              </Grid> </Grid>

            <Grid >
              <Box color="text.primary" clone>
                <Grid item sm={12} xs={12}>
                  <Paper style={{ backgroundColor: "white" }}>
                    <Typography variant="h5" gutterBottom align="center" style={{ 'color': 'darkblue' }}>
                      India Electricity Consumption
                    </Typography>
                  </Paper>
                </Grid></Box> </Grid>
            <Grid container spacing={1}>

              <Grid item sm={6} xs={12} >
                <Card className={classes.root}>
                  <CardContent style={{ backgroundColor: "white" }}>
                    <Typography variant="h6" component="h6" align="center" style={{ 'color': 'purple' }}>
                      Daily Consumption in Million Units
                    </Typography>
                    <br /><br /><br /><br />
                    <LineChart pdata={p1data1} />
                    <Typography variant="body1" gutterBottom align='left'  style={{ 'color': 'grey','marginLeft':'30px', 'textIndent':'40px'}}>
                    Beyond everything electricity consumption is one of the important reality. How much at the end of the day was electricity consumed which indicates the demand which subsumes installed capacity etc.
                    What is visible here is the year over year growth in electricity uptake and growth. While pandemic had impacted 2021, it is heartening to see uptick in electricity consumption/demand.
                    Data is published on a weekly basis by POSOCO (Power Systems Operation Corporation Limited) which among others has consumption data. 
                    <br/>So far this year based on the data published <b style={{ 'color': 'blue' }}> September 02 2023 has the peak consumption at 5224 million units compared to previous peak of June 10 2022 had 4722 million units consumed</b> highest for the year.
                    It is <b style={{ 'color': 'blue' }}>higher than year 2021's peak at 4508 Million units on July 07 2021</b>.

                    </Typography>
                  </CardContent></Card>
              </Grid>
              <Grid item sm={6} xs={12} >
                <Card className={classes.root}>
                  <CardContent style={{ backgroundColor: "white" }}>
                    <Typography variant="h6" component="h6" align="center" style={{ 'color': 'purple' }}>
                      Total Consumption by State
                    </Typography>
                    <br /><br />
                    <ChoroplethMap mdata={cp1data1}></ChoroplethMap>
                    <Typography variant="body1" gutterBottom align='left'  style={{ 'color': 'grey','marginLeft':'30px', 'textIndent':'40px'}}>
                      Here we could see aggregate of electricity consumed by every state since 2021 based on weekly data published by POSOCO.
                      We could see some interesting trend here. <b style={{ 'color': 'blue' }}>Maharashtra, Gujarat and Uttarpradesh</b> are the top 3 states in consumption in that order.
                      <br/>Mahrashtra and Gujarat are not a surprise as they are industrialized and also have large installed capactiy. Uttarpradesh while has the fourth largest installed capacity has third highest consumption.
                      Tamilnadu which has third highest installed capacity is fourth largest in overall consumption.
                    </Typography>
                  </CardContent></Card>
              </Grid>
              <Grid item sm={12} xs={12} >
                <Card className={classes.root}>
                  <CardContent style={{ backgroundColor: "white" }}>
                    <Typography variant="h6" component="h6" align="center" style={{ 'color': 'purple' }}>
                      Daily Power Consumption based on Weekly reports Calendar View
                    </Typography>
                    <br/>
                    <Typography variant="body1" gutterBottom align='left'  style={{ 'color': 'grey','marginLeft':'30px', 'textIndent':'40px'}}>
                      Daily consumption data is presented in the calendar view with darker shades indicating higher consumption. It is similar to the time-series line chart except presented in calendar format.Weekly data is from Monday to Sunday.
                      It is obvious that there is 3 weeks of data missing, it is due to the fact data could not be extracted from the reports because of mal-formed content in the weekly reports.
                      
                      </Typography>
                    <CalendarView pdata={cvdata1} />
                    <br />
  
                  </CardContent></Card>
              </Grid>
            </Grid>
            <br /><br />
          </Container>
        </div>
      </div>
    );
  }
}
export default ElecApp;
