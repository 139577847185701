import React, { useState, useEffect, useRef } from 'react'
import * as d3 from 'd3'

export default function LineChartMulti({ pdata }) {
    const wrapperRef = useRef()
    const lineRef = useRef()
    let data = ''
    if (pdata?.pdata){
        if(pdata.optionalgroupkeyfilter){
            data = pdata.pdata.filter((d)=>{
                if(d[pdata.groupkey] !== pdata.optionalgroupkeyfilter){
                    return true
                }else{
                    return false
                }
            })
        }else{
            data = pdata.pdata
        }
        
    }
    const slidemarker = pdata.sliderxvalue
    useEffect(() => {
        if (data) {
            let tooltip = d3.select(".containerL1").append("div").attr("class", "toolTip");
            const prepdata = d3.group(data, d => { return d[pdata.groupkey] })
            let margin = { top: 10, right: 30, bottom: 30, left: 100 },
                width = 1000 - margin.left - margin.right,
                height = 800 - margin.top - margin.bottom;
            const svgl = d3.select(lineRef.current).select('svg').attr('viewBox', pdata.viewbox)
            const xExtent = d3.extent(data, d => { return +d[pdata.plotxvaluefield] })
            const yExtent = d3.extent(data, d => { return +d[pdata.plotyvaluefield] })
            const xScale = d3.scaleLinear().domain(xExtent).range([0, width])
            const yScale = d3.scaleLinear().domain(yExtent).range([height, 0])
            const yAxis = d3.axisLeft(yScale)
            const xAxis = d3.axisBottom(xScale).tickFormat(d3.format('d'))
            const clegend = d3.scaleOrdinal().domain(prepdata.keys()).range(pdata.colorgroup)
            svgl.select('.y-axis').style('transform', `translate(-20px)`).call(yAxis)
            svgl.select('.x-axis').style('transform', `translate(0px,${height + 10}px)`).call(xAxis.ticks(pdata.ticks))
        
            svgl.selectAll(".x-axis>.tick>text")
                .each(function (d, i) {
                    d3.select(this).style("font-size", pdata.tickfontsize);
                })
            svgl.selectAll(".y-axis>.tick>text")
                .each(function (d, i) {
                    d3.select(this).style("font-size", pdata.tickfontsize);
                })
            svgl.select('.y-label').append('text').attr('transform', `rotate(-90)`)
                .attr('y', 0 - margin.left - pdata.ylabelpositiontuner)
                .attr('x', 0 - height / 2)
                .attr('dy', '0.5em')
                .attr('text-anchor', 'middle')
                .text(pdata.ylabeltxt).style('font-size', pdata.labelfontsize).style('fill', pdata.labeltxtcolor)
            svgl.select('.x-label').append('text').attr('transform', `translate(${width / 2},${height + pdata.xlabelpositiontuner +margin.bottom + 30})`)
                .attr('text-anchor', 'middle')
                .text(pdata.xlabeltxt).style('font-size', pdata.labelfontsize).style('fill', pdata.labeltxtcolor)
            const lineGenerator = d3.line()
                .x(d => xScale(+d[pdata.plotxvaluefield]))
                .y(d => yScale(+d[pdata.plotyvaluefield]))
                .curve(d3.curveBasis)
            svgl.select('.lines').selectAll('g').data(prepdata).exit().remove()
            svgl.select('.lines').selectAll('g').data(prepdata).enter().append('path')
                .attr('d', d => lineGenerator(d[1])).attr('fill', 'None')
                .attr("stroke", function (d) { return clegend(d[0]) })
                .attr('stroke-width', pdata.defaultstrokewidth)
            svgl.selectAll('circle').data(data, key => { return data[pdata.datauniqueid] }).exit().remove()
            svgl.selectAll('circle').data(data, key => { return data[pdata.datauniqueid] }).enter().append('circle')
                .attr('cx', d => xScale(+d[pdata.plotxvaluefield]))
                .attr('cy', d => yScale(d[pdata.plotyvaluefield]))
                .attr('r', function (d) { if (+d[pdata.plotxvaluefield] === slidemarker) { return pdata.slidemarkerradius } else { return pdata.defaultmarkradius } })
                .attr('fill', function (d) { if (+d[pdata.plotxvaluefield] === slidemarker) { return pdata.slidemarkercolor } else { return clegend(d[pdata.groupkey]) } })
                .on("mousemove click touchstart", function (eventb, d) {
                    tooltip
                        .style("left", d3.pointer(eventb, svgl)[0] + 20 + "px")
                        .style("top", d3.pointer(eventb, svgl)[1] + 1 + "px")
                        .style("display", "inline-block")
                        .html(pdata.disptext[0]  + (d[pdata.plotxvaluefield]) + pdata.disptext[1]  + d[pdata.plotyvaluefield] + pdata.disptext[2]  + d[pdata.groupkey]);
                }).on("mouseout touchend", function (d) { tooltip.style("display", "none"); })
        }
    }, [data, slidemarker])
    if (!data) {
        return (
            <div>
                No Data to plot..
            </div>
        )
    } else {
        return (
            <div className='containerL1' ref={wrapperRef} >
                <div ref={lineRef}>
                    <svg>
                        <g className='lines' />
                        <g className='x-axis' />
                        <g className='y-axis' />
                        <g className='x-label' />
                        <g className='y-label' />
                    </svg>
                </div>
            </div>
        )
    }
}