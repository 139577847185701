import '../App.css'
import * as d3 from 'd3'
import React, { useState, useEffect, useRef } from 'react'
import LineChart from './LineChart'
import LineChartMulti from './LineChartMulti'
import ChoroplethMap from './ChoroplethMap'
import topo from '../data/india_districts.json'
import data from '../data/population.csv'
import popdata_age from '../data/population_by_age.csv'
import cesnsus_2011_topical from '../data/india_census_topical_2011.csv'
import cesnsus_2011 from '../data/india_census_2011.csv'
import tfr_india from '../data/tfr_india.csv'
import { styled } from '@mui/material/styles'
import { createTheme, ThemeProvider } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Slider from '@mui/material/Slider';


const useStyles = styled((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    slider: {
        track: {
            color: 'red'
        },
    },
    overrides: {
        MuiSlider: {
            thumb: {
                color: "yellow",
            },
            track: {
                color: 'red'
            },
            rail: {
                color: 'black'
            }
        }
    }
}));

const muiTheme = createTheme({
    palette: {
        primary: {
            main: '#3f50b5',
        },
    },
    overrides: {
        MuiSlider: {
            thumb: {
                color: "green",
                backgroundColor: 'green'
            },
            track: {
                height: 4,
                borderRadius: 2,
                color: "green"
            },
            rail: {
                color: 'green',
                backgroundColor: 'green',
            },
            mark: {
                color: 'green'

            },
            color: 'green',
            backgroundColor: 'green'
        }
    },
});


export default function PopApp() {
    const slider = useRef()
    const slider1 = useRef()
    const slider3 = useRef()
    const [pdata, setpData] = useState([])
    const [padata, setpaData] = useState([])
    const [p1data, setp1Data] = useState([])
    const [tfrdata, setTfrdata] = useState([])
    const [mData, setMData] = useState()
    const [m1Data, setM1Data] = useState()
    const [val, setVal] = useState(new Date().getFullYear())
    const [vall, setVall] = useState(new Date().getFullYear())
    const [valfr, setValfr] = useState(2020)
    const classes = useStyles()
    const updateRange = (e, data) => {
        setVal(data);
        setp1Data(prevData => { return { ...prevData, sliderxvalue: data } })
    };
    const updateRangeml = (e, data) => {
        setVall(data);
        setpaData(prevData => { return { ...prevData, sliderxvalue: data } })
    };
    const updateRangefr = (e, data) => {
        setValfr(data);
        setTfrdata(prevData => { return { ...prevData, sliderxvalue: data } })
    };
    useEffect(() => {
        let plotdata = []
        d3.csv(data).then((data) => {
            setpData(data.filter(d => {
                if (d.Code === 'IND') {
                    return true
                }
                else {
                    return false
                }
            }))
            setp1Data({
                pdata: data.filter(d => {
                    if (d.Code === 'IND') {
                        return true
                    }
                    else {
                        return false
                    }
                }),
                sliderxvalue: val,
                datauniqueid: 'Year',
                plotyvaluefield: 'Population_or_history_projection',
                plotxvaluefield: 'Year',
                disptext: ['Population/Projection Year <br> Year: ', '<br> Population: ', '<br> Country : '],
                additionaldisptext: ['Entity'],
                viewbox: [-400, -160, 1800, 1000],
                xlabeltxt: 'Year',
                ylabeltxt: 'Population',
                labeltxtcolor: 'green',
                labelfontsize: 40,
                xlabelpositiontuner: 15,
                ylabelpositiontuner: 140,
                defaultmarkradius: 4,
                defaultstrokewidth: 3,
                defaultmarkcolor: 'green',
                defaultstrokecolor: 'green',
                highmarkradius: 10,
                highmarkcolor: 'green',
                slidemarkercolor: 'darkblue',
                slidemarkerradius: 10,
                tickfontsize: 28,
                ticks: 7,
                xtickformat: 'number'
            })
        })
        d3.csv(popdata_age).then((data) => {
            let totData = data.filter(d => {
                if (d.Code === 'IND') {
                    return true
                }
                else {
                    return false
                }
            })
            let prepdata = totData.map((data) => {
                if (+data.Year <= 2020) {
                    return { Code: data.Code, Entity: data.Entity, Measure: 'Under 5', Value: data.Estimates_thousands_Population_5_under, Year: data.Year, id: data.Year + ' Under 5' }
                } else {
                    return { Code: data.Code, Entity: data.Entity, Measure: 'Under 5', Value: data.Medium_fertility_variant_thousands_Population_5_under, Year: data.Year, id: data.Year + ' Under 5' }
                }
            })
            prepdata = [...prepdata, ...totData.map((data) => {
                if (+data.Year <= 2020) {
                    return { Code: data.Code, Entity: data.Entity, Measure: 'Under 15', Value: data.Estimates_thousands_Population_15_under, Year: data.Year, id: data.Year + ' Under 15' }
                } else {
                    return { Code: data.Code, Entity: data.Entity, Measure: 'Under 15', Value: data.Medium_fertility_variant_thousands_Population_15_under, Year: data.Year, id: data.Year + ' Under 15' }

                }
            })]
            prepdata = [...prepdata, ...totData.map((data) => {
                if (+data.Year <= 2020) {
                    return { Code: data.Code, Entity: data.Entity, Measure: '25 to 64', Value: data.Estimates_thousands_Population_25_64, Year: data.Year, id: data.Year + ' 25 to 64' }
                } else {
                    return { Code: data.Code, Entity: data.Entity, Measure: '25 to 64', Value: data.Medium_fertility_variant_thousands_Population_25_64, Year: data.Year, id: data.Year + ' 25 to 64' }

                }
            })]
            prepdata = [...prepdata, ...totData.map((data) => {
                if (+data.Year <= 2020) {
                    return { Code: data.Code, Entity: data.Entity, Measure: 'Under 25', Value: data.Estimates_thousands_Population_25_under, Year: data.Year, id: data.Year + ' Under 25' }
                } else {
                    return { Code: data.Code, Entity: data.Entity, Measure: 'Under 25', Value: data.Medium_fertility_variant_thousands_Population_25_under, Year: data.Year, id: data.Year + ' Under 25' }
                }
            })]
            prepdata = [...prepdata, ...totData.map((data) => {
                if (+data.Year <= 2020) {
                    return { Code: data.Code, Entity: data.Entity, Measure: 'Above 65', Value: data.Estimates_thousands_Population_65_above, Year: data.Year, id: data.Year + ' Above 65' }
                } else {
                    return { Code: data.Code, Entity: data.Entity, Measure: 'Above 65', Value: data.Medium_fertility_variant_thousands_Population_65_above, Year: data.Year, id: data.Year + ' Above 65' }
                }
            })]
            prepdata = [...prepdata, ...totData.map((data) => {
                if (+data.Year <= 2020) {
                    return { Code: data.Code, Entity: data.Entity, Measure: 'Total', Value: data.Estimates_thousands_Total, Year: data.Year, id: data.Year + ' Total' }
                } else {
                    return { Code: data.Code, Entity: data.Entity, Measure: 'Total', Value: data.Medium_fertility_variant_thousands_Total, Year: data.Year, id: data.Year + ' Total' }
                }
            })]
            setpaData({
                pdata: prepdata,
                sliderxvalue: 2021,
                datauniqueid: 'id',
                viewbox: [-400, -200, 1800, 1100],
                groupkey: 'Measure',
                optionalgroupkeyfilter: 'Total',
                plotyvaluefield: 'Value',
                plotxvaluefield: 'Year',
                disptext: ['Population/Projection Year <br> Year: ', '<br> Population: ', '<br> Age Group : '],
                additionaldisptext: ['Measure'],
                xlabeltxt: 'Year',
                ylabeltxt: 'Population',
                labeltxtcolor: 'green',
                labelfontsize: 40,
                xlabelpositiontuner: 20,
                ylabelpositiontuner: 120,
                defaultmarkradius: 3,
                defaultstrokewidth: 3,
                colorgroup: ['#e41a1c', '#377eb8', '#4daf4a', '#984ea3', '#884DFF', '#ffff33', '#a65628'],
                highmarkradius: 5,
                highmarkcolor: 'green',
                slidemarkercolor: 'darkblue',
                slidemarkerradius: 10,
                tickfontsize: 28,
                ticks: 7,
            })
        })
        d3.csv(tfr_india).then((data) => {
            setTfrdata({
                pdata: data,
                sliderxvalue: valfr,
                datauniqueid: 'Year',
                plotyvaluefield: 'TFR',
                plotxvaluefield: 'Year',
                disptext: ['Total Fertility Rate <br> Year: ', '<br> TFR: ', '<br> Country : '],
                additionaldisptext: ['Country'],
                viewbox: [-400, -140, 1800, 1000],
                xlabeltxt: 'Year',
                ylabeltxt: 'Total Fertility Rate',
                labeltxtcolor: 'green',
                labelfontsize: 40,
                xlabelpositiontuner: 25,
                ylabelpositiontuner: 20,
                defaultmarkradius: 5,
                defaultstrokewidth: 4,
                defaultmarkcolor: 'green',
                defaultstrokecolor: 'green',
                highmarkradius: 5,
                highmarkcolor: 'green',
                slidemarkercolor: 'darkblue',
                slidemarkerradius: 10,
                tickfontsize: 28,
                ticks: 6,
            })
        })

        d3.csv(cesnsus_2011_topical).then((data) => {
            setMData({
                mapd: topo,
                viewbox: [640, 140, 120, 100],
                feature: ['objects', 'states'],
                deftxtlabel: ['properties', 'st_nm'],
                pdata: data.filter((d) => {
                    if (d.TRU === 'Total' && d.Level === 'STATE') {
                        return true
                    }
                }),
                pkey: 'Name',
                topokey: 'st_nm',
                pplotkey: 'Total Population Person',
                disptext: ['Population Based on 2011 Census <br> Population: ', '<br> State : '],
                domain: [60000, 500000, 10000000, 25000000, 50000000, 75000000, 100000000, 150000000, 190000000],
                colorscheme: d3.schemeBuPu[9],
            })
        })
        d3.csv(cesnsus_2011).then((data) => {
            setM1Data({
                mapd: topo,
                viewbox: [640, 140, 120, 100],
                feature: ['objects', 'states'],
                deftxtlabel: ['properties', 'st_nm'],
                pdata: data,
                pkey: 'State',
                topokey: 'st_nm',
                pplotkey: 'Sex Ratio - 2011',
                disptext: ['Sex Ratio Based on 2011 Census <br> Females per 1000 Males: ', '<br> State : '],
                domain: [900, 910, 920, 930, 940, 950, 980, 1000, 1090],
                colorscheme: d3.schemeRdYlGn[9],
            })
        })

    }, [])
    if (!p1data?.pdata || !p1data?.sliderxvalue || !padata?.pdata || !m1Data?.pdata || !tfrdata?.pdata || !mData?.pdata) {
        return (<div>Loading </div>)
    }
    else if (p1data?.pdata && padata?.pdata && m1Data?.pdata && tfrdata?.pdata && mData?.pdata) {
        return (
            <div className="App">
                <div className={classes.root}>
                    <p></p>
                    <Container >

                        <Box color="text.primary" clone>
                            <Grid item sm={12} xs={12}>
                                <Paper style={{ backgroundColor: "white" }}>
                                    <Typography variant="h4" gutterBottom align="center" style={{ 'color': 'darkblue' }}>
                                        Population Story of India
                                    </Typography>
                                </Paper>
                            </Grid></Box>

                        <Grid container spacing={1}>


 

                            <Grid item sm={6} xs={12}>
                                <Card className={classes.root}>
                                    <CardContent style={{ backgroundColor: "white" }}>
                                        <Typography variant="h6" component="h6" align="center" style={{ 'color': 'darkblue' }}>
                                            Population and projection estimates for India, a Time Series Plot
                                        </Typography>
                                        <Typography variant="h5" component="h2">
                                            <br />
                                        </Typography>
                                        <LineChart pdata={p1data} /> <br />

                                        <Typography id="discrete-slider" gutterBottom style={{ 'color': 'darkblue' }}>
                                            Year Slider
                                        </Typography>
                                        <ThemeProvider theme={muiTheme}>
                                            <Slider
                                                defaultValue={2021}
                                                value={val}
                                                onChange={updateRange}
                                                aria-labelledby="discrete-slider"
                                                valueLabelDisplay="auto"
                                                step={1}
                                                min={1800}
                                                max={2100}
                                                ref={slider} />
                                        </ThemeProvider>
                                        <Typography variant="body2" align="left" component="p" style={{ 'color': 'darkblue' }}>
                                            India will soon become the world’s most populous country as India’s population is predicted to surpass that of China within the next decade before 2040.
                                            India’s population is expected to continue to grow until mid-century, reaching the peak in the tail end of 2050's as the chart shows.
                                            India's population is expected to peak by the year <b style={{ 'color': 'blue' }}>2059</b> reaching <b style={{ 'color': 'blue' }}>1.65 billion</b> and will start to decline.
                                            <p>
                                                While population story and data in the short term seem to convey that population of Bharat is still growing but infact it is just the momentum. We will see in the later data sets plotted below
                                                why it is so.
                                            </p>
                                            <p>

                                                You can use the slider to a specific year to help better identify the data for the specific year by hovering/touching the white circle. Hovering over the line will provide the population/population-estimate for the year.
                                                <br /><br />Data is based on the UN Population Projection estimates which can be found here.
                                                <a href="https://population.un.org/wpp/DataQuery/">https://population.un.org/wpp/DataQuery/</a><br />

                                            </p>


                                        </Typography>
                                        <p><br /><br /><br /><br /><br /><br /></p><br />
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item sm={6} xs={12}>
                                <Card className={classes.root}>
                                    <CardContent style={{ backgroundColor: "white" }}>
                                        <Typography variant="h6" component="h6" align="center" style={{ 'color': 'darkblue' }}>
                                            Population and projection estimates for India by Age, a Time Series Plot
                                        </Typography>
                                        <LineChartMulti pdata={padata} /> <br />

                                        <Typography id="discrete-slider" gutterBottom style={{ 'color': 'darkvlue' }}>
                                            Year Slider
                                        </Typography>

                                        <ThemeProvider theme={muiTheme}>
                                            <Slider
                                                defaultValue={2021}
                                                value={vall}
                                                onChange={updateRangeml}
                                                aria-labelledby="discrete-slider"
                                                valueLabelDisplay="auto"
                                                step={1}
                                                min={1950}
                                                max={2100}
                                                ref={slider1} />
                                        </ThemeProvider>
                                        <Typography variant="body2" align="left" component="p" style={{ 'color': 'darkblue' }}>
                                            India’s population is expected to continue to grow until mid-century, as the previous chart shows. But an important piece of evidence tells us that population growth will come to an end: The number of children in India peaked more than a decade ago and is now falling.
                                            This chart shows the population change since 1950 and the UN’s projections of population by age bracket.
                                            Here we see that the number of children <b style={{ 'color': 'blue' }}>under the age of five (under-5s) peaked in 2007</b>; since then the number has been falling.
                                            The number of Indians <b style={{ 'color': 'blue' }}>under 15 years old peaked slightly later (in 2011)</b> and is now also declining.
                                            Another interesting thing to note is that by year <b style={{ 'color': 'blue' }}>2014 the population in the age group 25 to 64 crossed the population under age 25</b>.
                                            These are landmark moments in demographic change.
                                            <p>India’s population will still continue to grow as a result of ‘population momentum’ – the effect often referred to by Hans Rosling and Gapminder as the ‘inevitable fill-up‘ when young generations grow older.
                                                But we can now see an end to population growth: reaching ‘peak child‘ anticipates the later ‘peak population’.
                                                <br />The number of children has peaked; total population will follow and reach its peak in four decades.
                                                These could have some interesting consequences if the society is not careful and could lead to some gender imbalance. We would see the gender imbalance (sex ratio) in another chart below.
                                                In addition we would have significant amount of population in the age group of 25-65 and 65 and above. Lot of jobs and services will be required to cater to the changing age group of the society be it old age care or the number of youth available to do manual work.
                                                It could spur some automation in the services sector.</p>

                                        </Typography>

                                    </CardContent></Card>
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <Card className={classes.root}>
                                    <CardContent style={{ backgroundColor: "white" }}>
                                        <Typography variant="h6" component="h6" align="center" style={{ 'color': 'darkblue' }}>
                                            Total Fertility Rate of India, a Time Series Plot
                                        </Typography>
                                        <Typography variant="h5" component="h2">
                                            <br />
                                        </Typography>
                                        <LineChart pdata={tfrdata} />

                                        <Typography id="discrete-slider" gutterBottom style={{ 'color': 'darkblue' }}>
                                            Year
                                        </Typography>
                                        <ThemeProvider theme={muiTheme}>
                                            <Slider
                                                defaultValue={2020}
                                                value={valfr}
                                                onChange={updateRangefr}
                                                aria-labelledby="discrete-slider"
                                                valueLabelDisplay="auto"
                                                step={5}
                                                min={1955}
                                                max={2100}
                                                ref={slider3} />
                                        </ThemeProvider>
                                        <Typography variant="body2" align="left" component="p" style={{ 'color': 'darkblue' }}>
                                            India's population continues to increase and it has the momentum to reach the country with the largest population in the world overtaking China by 2040.
                                            Utilizing yearly population projection or population from the census that happens every decade does not truly reflect the story. While it is true that the population has continued to climb, one of the main indicators is how many kids those families tend to have.
                                            <p>
                                                One remarkable data indicates that while population growth has its momentum it is going to decline after a while.
                                                TFR total fertility rate is that indicator which helps us get a better narrative for the population growth story.
                                                <p style={{ 'color': 'blue' }}> What is Total Fertility Rate (TFR)? <br />
                                                    TFR is the average number of children women will have in their child bearing age between 15 and 49 years of age. </p>
                                                <p>It will determine the future population momentum.
                                                    If women bear fewer and fewer children over a period of time population growth will tend to stabilize and when it goes below 2 the population will tend to decline.
                                                    Data is indicating that TFR has gone down significantly since Bharat's independence from <b style={{ 'color': 'blue' }}>6 kids per family to 2.3 kids in 2020</b>.
                                                    By <b style={{ 'color': 'blue' }}>2030 TFR will be below 2</b> starting the process of population decline.</p>


                                            </p>
                                            <br />
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Card className={classes.root}>
                                    <CardContent style={{ backgroundColor: "white" }}>
                                        <Typography variant="h6" component="h6" align="center" style={{ 'color': 'darkblue' }}>
                                            Population Of States of India based on 2011 Census
                                        </Typography>
                                        <ChoroplethMap mdata={mData}></ChoroplethMap>
                                        <Typography variant="body2" align="left" component="p" style={{ 'color': 'darkblue' }}>
                      <p>India while it is not a small country, it is not the largest country either when compared to other large countries in the world. One interesting factor is that India has large available arable lands when compared to China.
                        Many would find it interesting that arable land of India is indeed higher compared to China. It is one factor that has enabled higher population and density compared to other countires and relatively moderate tropical climate has enabled growth of human life.</p>
                      <p> While aggregate numbers tell some story, but let us look at how the population is distributed geographically across India.
                        It is evident from the population distribution in the map that states in the gangetic plains are of higher density compared to the rest of India.
                        While <b style={{ 'color': 'blue' }}>Uttar Pradesh ranks 4th in the geographic area but it tops the population and density</b>.
                        Second largest population goes to Maharashtra even though it is not in the gangetic plains but it too has its fair share of rivers originating in the Western Ghats.
                        Bihar and West Bengal are the third and fourth largest states. It is clear that <b style={{ 'color': 'blue' }}>fertile Gangetic Plains and Gangetic Delta sustains large human habitation</b> as water is abundant in those states.
                        Andhra Pradesh was one state during the 2011 census and it was later split into two one as Andhra Pradesh and the other as Telangana.
                        Jammu and Kashmir was split into Jammu and Kashmir and Ladakh as well.
                        Data for this chart is based on the 2011 Census Data available from <a href="https://api.data.gov.in//resource/0764657f-00ec-4c6b-9ece-2d7b8a7401fa">data.gov,in</a>

                      </p>
                      <br />
                    </Typography>
                    <p><br/><br/><br/><br/><br/><br/></p>
                                    </CardContent></Card>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Card className={classes.root}>
                                    <CardContent style={{ backgroundColor: "white" }}>
                                        <Typography variant="h6" component="h6" align="center" style={{ 'color': 'darkblue' }}>
                                            Sex Ratio Of States of India based on 2011 Census
                                        </Typography>
                                        <ChoroplethMap mdata={m1Data}></ChoroplethMap>
                                        <Typography variant="body2" align="left" component="p" style={{ 'color': 'darkblue' }}>
                      In the previous data charts we delved into population growth, population by age and the important factor of Total Fertility Rate (TFR).
                      We then looked at the geographic distribution of the population within India and found that human habitat really thrives in the Gangetic plains and along other river plains.
                      In this visualization we will look into Sex Ratio. It is another important factor which could have implications on both population growth and societal unrest.
                      <p style={{ 'color': 'blue' }}>
                        So what is Sex Ratio?
                        <br /> Sex Ratio is the total number of Females per 1000 Males in a population. In general a healthy species should have 1:1 (i.e. for every 1000 male there are 1000 females available.)
                      </p>
                      <p>
                        According to <b style={{ 'color': 'blue' }}> 2011 Census Sex Ratio is 943</b>. It is not a healthy number for the society.
                        Now let us look at the how the Sex Ratio is spread across the States of Bharat. It is interesting to note that Northern India and Western India has the most skewed Sex Ratio.
                        <b style={{ 'color': 'blue' }}>NCT of Delhi is the worst with 868 females per 1000 males.</b>
                        It is followed by <b style={{ 'color': 'blue' }}> Haryana, Punjab and Jammu and Kashmir</b>
                        <b style={{ 'color': 'blue' }}>NCT of Delhi, Haryana, Punjab, Uttar Pradesh, Bihar and Gujarat are the worst performers </b> in Sex Ratio.

                      </p>
                      <p>
                        Southern States of Kerala, Tamil Nadu, Andhra Pradesh and the eastern state of Chhattisgarh are the best performers.
                        In the next decade the states of <b style={{ 'color': 'blue' }}>Kerala, Tamil Nadu, Andhra Pradesh, Telangana and Karnataka are going to have the Sex Ratio above 1000</b> while Kerala and Tamil Nadu as of 2020 already have more females than males.
                        One factor indicate education and availability of power has made postiive impact on the Southern States.
                        <br />
                        Jammu and Kashmir was a single state during 2011 census which was subsequently split into Jammu and Kashmir and Ladakh.
                        Andhra Pradesh was also a single state during 2011 census which was subsequntly split into Andhra Pradesh and Telangana.
                        Data for this chart is based on the 2011 Census Data available from <a href="https://api.data.gov.in//resource/0764657f-00ec-4c6b-9ece-2d7b8a7401fa">data.gov,in</a>
                      </p>
                    </Typography>
                                    </CardContent></Card>
                            </Grid>

                        </Grid>

                    </Container>
                </div>
            </div>
        )
    }
}
