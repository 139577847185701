import './App.css'
import React, { useState } from 'react'
import { HashRouter as Router, Routes, Route, Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { createTheme, ThemeProvider } from '@mui/material'
import Typography from '@mui/material/Typography'
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material//useMediaQuery'
import useTheme from '@mui/material/styles/useTheme'
import { List, ListItemButton, ListItemIcon, ListItemText, ListItem } from '@mui/material/'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ElecApp from './components/ElecApp'
import PopApp from './components/PopApp'
import HomeApp from './components/HomeApp'
const useStyles = styled((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    slider: {
        track: {
            color: 'red'
        },
    },
    overrides: {
        MuiSlider: {
            thumb: {
                color: "yellow",
            },
            track: {
                color: 'red'
            },
            rail: {
                color: 'black'
            }
        }
    }
}));

export default function App() {
    const classes = useStyles()
    const [tabselected, settabselected] = useState(0)
    const [openDrawer, setOpenDrawer] = useState(false);
    const theme = useTheme()
    const ismedium = useMediaQuery(theme.breakpoints.down("md"))
    const handletabselect = (event, index) => {
        settabselected(index)
    }
    return (
        <div className="App">
            <div className={classes.root}>
                <Router>
                    <AppBar position="static" style={{ background: '#444eba' }}>
                        <Toolbar disableGutters>
                            {ismedium ? (
                                <>
                                    <IconButton
                                        size="large"
                                        aria-label="menu for visual narrative"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        color="inherit"
                                        onClick={() => setOpenDrawer(!openDrawer)}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                        Visual Story Series
                                    </Typography>
                                    <Drawer anchor="left" open={openDrawer} onClose={() => setOpenDrawer(false)} >
                                        <List>
                                            <ListItem key='1'>
                                                <ListItemButton key='1' onClick={() => setOpenDrawer(!openDrawer)}>
                                                    <ListItemIcon>
                                                        <ListItemText>
                                                            <Link to='/' style={{ color: 'inherit', textDecoration: 'none' }}>Home
                                                            </Link>
                                                        </ListItemText>
                                                    </ListItemIcon>
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem key='2'>
                                                <ListItemButton key='2' onClick={() => setOpenDrawer(!openDrawer)}>
                                                    <ListItemIcon>
                                                        <ListItemText>
                                                            <Link to='/IndiaElectricity' style={{ color: 'inherit', textDecoration: 'none' }}>Electricity
                                                            </Link>
                                                        </ListItemText>
                                                    </ListItemIcon>
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem key='3'>
                                                <ListItemButton key='3' onClick={() => setOpenDrawer(!openDrawer)}>
                                                    <ListItemIcon>
                                                        <ListItemText>
                                                            <Link to='/IndiaPopulation' style={{ color: 'inherit', textDecoration: 'none' }}>Population
                                                            </Link>
                                                        </ListItemText>
                                                    </ListItemIcon>
                                                </ListItemButton>
                                            </ListItem>
                                        </List>
                                    </Drawer>
                                </>

                            ) : (<>
                                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                    Visual Story Series
                                </Typography>
                                <Tabs value={tabselected} onChange={handletabselect} textColor='inherit' style={{ alignItems: 'right' }}>
                                    <Tab label="Home" component={Link} to='/' />
                                    <Tab label="Electricity" component={Link} to='/IndiaElectricity' />
                                    <Tab label="Population" component={Link} to='/IndiaPopulation' />
                                </Tabs>
                            </>
                            )}
                        </Toolbar>
                    </AppBar>
                    <Routes>
                        <Route exact path='/IndiaElectricity' element={<ElecApp />} />
                        <Route exact path='/IndiaPopulation' element={<PopApp />} />
                        <Route exact path='/' element={<HomeApp />} />
                    </Routes>
                </Router>
            </div>
        </div>
    )
}
