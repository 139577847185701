import React, { useRef, useEffect } from 'react'
import * as d3 from 'd3'
import { format } from 'date-fns'

export default function CalendarView({ pdata }) {
    Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf())
        date.setDate(date.getDate() + days)
        return date
    }
    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }
    const svgCref = useRef()
    const Wrapperref = useRef()
    let formatDate = d3.utcFormat("%x")
   
    useEffect(()=>{

        let plotdata = pdata.pdata.map((d) => {
            return ({
                //date: new Date(d[pdata.plotkey]).setHours(0,0,0,0),
                date: new Date(d[pdata.plotkey]),
                value: d[pdata.plotvaluefield],
                dateval: new Date(d[pdata.plotkey]).setHours(0,0,0,0),
            })
        })
        let colorScale = d3.scaleThreshold()
            .domain(pdata.domain)
            .range(pdata.colorscheme)
        let margin = { top: 10, right: 30, bottom: 30, left: 100 },
            width = 1000 - margin.left - margin.right,
            height = 800 - margin.top - margin.bottom;
        let eendyear = pdata.endyear + 1
        let daytext = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        const datestart = new Date('01/01/' + pdata.startyear)
        const dateend = new Date('01/01/' + eendyear)
        const cellsize = 12
        const dates = d3.timeDays(datestart, dateend)
        const years = dates.map((d) => {
            return d.getUTCFullYear()
        }).filter(onlyUnique)
        let labels = dates.map((d, i) => {
            let weekdayletter = ''
            let monthletter = ''
            if (d.getDay() === 0) {
                weekdayletter = 'Sun'
            } else if (d.getDay() === 1) {
                weekdayletter = 'Mon'
    
            } else if (d.getDay() === 2) {
                weekdayletter = 'Tue'
    
            } else if (d.getDay() === 3) {
                weekdayletter = 'Wed'
    
            } else if (d.getDay() === 4) {
                weekdayletter = 'Thu'
    
            } else if (d.getDay() === 5) {
                weekdayletter = 'Fri'
    
            } else if (d.getDay() === 6) {
                weekdayletter = 'Sat'
            }
            if (d.getMonth() === 0) {
                monthletter = 'Jan'
            } else if (d.getMonth() === 1) {
                monthletter = 'Feb'
            } else if (d.getMonth() === 2) {
                monthletter = 'Mar'
            } else if (d.getMonth() === 3) {
                monthletter = 'Apr'
            } else if (d.getMonth() === 4) {
                monthletter = 'May'
            } else if (d.getMonth() === 5) {
                monthletter = 'Jun'
            } else if (d.getMonth() === 6) {
                monthletter = 'Jul'
            } else if (d.getMonth() === 7) {
                monthletter = 'Aug'
            } else if (d.getMonth() === 8) {
                monthletter = 'Sep'
            } else if (d.getMonth() === 9) {
                monthletter = 'Oct'
            } else if (d.getMonth() === 10) {
                monthletter = 'Nov'
            } else if (d.getMonth() === 11) {
                monthletter = 'Dec'
            }
            return (
                {
                    id: i,
                    month: d.getMonth(),
                    weekday: d.getDay(),
                    weekdaymonthyear: String(weekdayletter) + ' ' + String(monthletter) + ' ' + String(d.getFullYear()),
                    monthyear: String(monthletter) + ' ' + String(d.getFullYear()),
                    cdate: d
                })
        })
        let monthuniques = labels.map((d) => { return d.monthyear }).filter(onlyUnique)
        let monthlabels = monthuniques.map((d) => {
            if (d.substring(0, 3) === 'Jan' || d.substring(0, 3) === 'Feb' || d.substring(0, 3) === 'Mar') {
                return ({ my: d, qtr: 0 })
            } else if (d.substring(0, 3) === 'Apr' || d.substring(0, 3) === 'May' || d.substring(0, 3) === 'Jun') {
                return ({ my: d, qtr: 1 })
            } else if (d.substring(0, 3) === 'Jul' || d.substring(0, 3) === 'Aug' || d.substring(0, 3) === 'Sep') {
                return ({ my: d, qtr: 2 })
            } else if (d.substring(0, 3) === 'Oct' || d.substring(0, 3) === 'Nov' || d.substring(0, 3) === 'Dec') {
                return ({ my: d, qtr: 3 })
            }
        })
        let tooltip = d3.select(".containerM").append("div").attr("class", "toolTip")
        const svgc = d3.select(svgCref.current).select('svg').attr('viewBox', pdata.viewbox)
        svgc.selectAll('rect').data(dates, key => dates).exit().remove()
        svgc.selectAll('rects').data(dates, key => dates)
            .enter()
            .append('rect')
            .attr('x', (d) => {
                let monthOffset = (d.getMonth()) * (cellsize + 2) * 7
                let yoffset = (Math.floor(d.getMonth() / 3))
                if (yoffset > 0) {
                    return ((d.getUTCDay() * cellsize + 0.5 + monthOffset) - (((21 * cellsize) + (cellsize + 2) * 3)) * yoffset)
                } else {
                    return d.getUTCDay() * cellsize + 0.5 + monthOffset
                }
            }
            )
            .attr('y', (d) => {
                let moffset = (Math.floor(d.getMonth() / 3)) * (7 * (cellsize + 1))
                let yearindex = years.findIndex((dat) => {
                    if (dat === d.getUTCFullYear()) {
                        return true
                    } else {
                        return false
                    }
                })
                let yearoffset = (yearindex * (24 * cellsize) + yearindex * (8 * cellsize))
                return d3.timeWeek.count(d3.utcMonth(d), d) * cellsize + 0.5 + moffset + yearoffset
            })
            .attr('height', cellsize - 1)
            .attr('width', cellsize - 1)
            .attr('fill', (d) => {
                let cval = plotdata.find((dt) => dt['dateval'] === d.getTime())
                if (!cval) {
                    return pdata.defaultcolor
                } else {
                    return colorScale(+cval.value)
                }
            })
            .attr('opacity', pdata.fillopacity)
            .on("mousemove click touchstart", function (eventb, d) {
                let txt = plotdata.find((dt) => dt['dateval'] === d.getTime())
                let dispval1, dispval2 = ''
                if (txt) {
                    dispval1 = format(txt.date,'dd/MM/yyyy')//txt.date.toDateString()
                    dispval2 = txt.value
                } else {
                    dispval1 = 'NA'
                    dispval2 = 'NA'
                }
                tooltip
                    .style("left", d3.pointer(eventb, svgc)[0] + 20 + "px")
                    .style("top", d3.pointer(eventb, svgc)[1] + 1 + "px")
                    .style("display", "inline-block")
                    .html(pdata.disptext[0] + dispval1 + pdata.disptext[1] + dispval2);
            }).on("mouseout touchend", function (d) { tooltip.style("display", "none"); })
            .append("title")
            .text(d => `${formatDate(d)}`)
    
        monthlabels.forEach((d, i) => {
            svgc.selectAll('daytexts').data(daytext, key => daytext).exit().remove()
            svgc.selectAll('daytexts')
                .data(daytext, key => daytext)
                .enter()
                .append('text')
                .attr('x', (di, ii) => {
                    let xval = 0
                    if (d.qtr === 0) {
                        let ind = 0
                        let yindex = Math.floor(i / 12)
                        if (yindex === 0) {
                            ind = i
                        } else {
                            ind = i - 12 * yindex
                        }
                        xval = (cellsize + 2) * 7 * ind + (cellsize * ii)
                    } else {
                        let ind = 0
                        let yindex = Math.floor(i / 12)
                        if (yindex === 0) {
                            ind = i
                        } else {
                            ind = i - 12 * yindex
                        }
                        xval = (cellsize + 2) * 7 * ind + (cellsize * ii) - (d.qtr * ((cellsize + 2) * 21))
                    }
                    return xval
                })
                .attr('y', (di, ii) => {
                    let yindex = Math.floor(i / 12)
                    let yval = 0
                    if (d.qtr > 0) {
                        yval = (cellsize + 1) * 7 * d.qtr - ((2.4) * d.qtr) + 24 * (cellsize) * yindex + yindex * (8 * cellsize) - 2
                    } else {
                        yval = (cellsize + 1) * 7 * d.qtr - ((2.4) * d.qtr) + 24 * (cellsize) * yindex + yindex * (8 * cellsize) - 2
                    }
                    return yval
                })
                .text((d) => { return d })
                .attr('font-size', 3)
        })
        svgc.selectAll('mtexts').data(monthlabels, key => monthlabels.my).exit().remove()
    
        svgc.selectAll('mtexts')
            .data(monthlabels, key => monthlabels.my)
            .enter()
            .append('text')
            .attr('x', (d, i) => {
                let xval = 0
                if (d.qtr === 0) {
                    let ind = 0
                    let yindex = Math.floor(i / 12)
                    if (yindex === 0) {
                        ind = i
                    } else {
                        ind = i - 12 * yindex
                    }
                    xval = (cellsize + 2) * 7 * ind
                } else {
                    let ind = 0
                    let yindex = Math.floor(i / 12)
                    if (yindex === 0) {
                        ind = i
                    } else {
                        ind = i - 12 * yindex
                    }
                    xval = (cellsize + 2) * 7 * ind - (d.qtr * ((cellsize + 2) * 21))
                }
                return xval
            })
            .attr('y', (d, i) => {
                let ind = 0
                let yindex = Math.floor(i / 12)
                let yval = 0
    
                if (d.qtr > 0) {
                    yval = (cellsize + 1) * 7 * d.qtr - ((2.4) * d.qtr) + 24 * (cellsize) * yindex + yindex * (8 * cellsize) - 6.5
                } else {
                    yval = (cellsize + 1) * 7 * d.qtr - ((2.4) * d.qtr) + 24 * (cellsize) * yindex + yindex * (8 * cellsize) - 7
                }
                return yval
            })
            .text((d) => { return d.my })
            .attr('font-size', 3)
    

    },[pdata])
        return (
        <div ref={Wrapperref}>
            <div className='containerM' ref={svgCref}>
                <svg>
                </svg>
            </div>
         
        </div>
    )
}
