import React, { useRef, useEffect } from 'react'
import * as d3 from 'd3'
import * as d3legend from 'd3-svg-legend'

export default function CalendarHeatMap({ pdata }) {

    Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf())
        date.setDate(date.getDate() + days)
        return date
    }
    let margin = { top: 10, right: 30, bottom: 30, left: 100 },
        width = 1000 - margin.left - margin.right,
        height = 800 - margin.top - margin.bottom;
    const heatMapref = useRef()
    const wrapperRef = useRef()
    let tooltip = d3.select(".containerh").append("div").attr("class", "toolTip")
    const svgh = d3.select(heatMapref.current).select('svg').attr('viewBox', pdata.viewbox)
    let dateExtent = d3.extent(pdata.pdata, d => d[pdata.plotyvaluefield])
    dateExtent = dateExtent.map((d) => { return new Date(d) })
    dateExtent[1] = dateExtent[1].addDays(1)
    let xValues = pdata.pdata.map((d) => { return d[pdata.plotxvaluefield] })
    let colorExtent = d3.extent(pdata.pdata, d => +d[pdata.plotvaluefield])
    let color = d3.scaleSequential(colorExtent, pdata.fillcolor)
    let dscale = d3.scaleBand().domain(d3.timeDays(...dateExtent)).range([0, height])
    let xscale = d3.scaleBand().domain(xValues).range([0, width])
    let yAxis = d3.axisLeft(dscale).tickFormat((d) => {
        if (d.getMonth() === 0 && d.getDate() === 1) {
            return d3.timeFormat("%b %-d %-Y")(d)
        } else if (d.getMonth() !== 0 && d.getDate() === 1) {
            return d3.timeFormat("%b %-d")(d)
        } else {
            return d3.timeFormat("%-d")(d)
        }
    })
    let xAxis = d3.axisBottom(xscale)
    let legendLinear = d3legend.legendColor()
                               .shapeWidth(90)
                               .orient('horizontal')
                               .scale(color)
                               .labelFormat(d3.format("d"))
                               .title("Living Index Score")
    svgh.select('.y-axis').style('transform', `translate(-20px)`).call(yAxis)
    svgh.selectAll(".y-axis>.tick>text")
        .each(function (d, i) {
            d3.select(this).style("font-size", pdata.tickfontsize);
        })
    svgh.select('.x-axis').style('transform', `translate(0px,${height + 10}px)`).call(xAxis.ticks(pdata.ticks))
    svgh.selectAll(".x-axis>.tick>text")
        .each(function (d, i) {
            d3.select(this).style("font-size", pdata.tickfontsize);
        })
    svgh.select('.y-label').append('text').attr('transform', `rotate(-90)`)
        .attr('y', 0 - margin.left - pdata.ylabelpositiontuner)
        .attr('x', 0 - height / 2)
        .attr('dy', '0.5em')
        .attr('text-anchor', 'middle')
        .text(pdata.ylabeltxt).style('font-size', pdata.labelfontsize).style('fill', pdata.labeltxtcolor)
    svgh.select('.x-label').append('text').attr('transform', `translate(${(width / 2)},${height + pdata.xlabelpositiontuner + margin.bottom + 30})`)
        .attr('text-anchor', 'middle')
        .text(pdata.xlabeltxt).style('font-size', pdata.labelfontsize).style('fill', pdata.labeltxtcolor)
    svgh.append("g")
        .attr("class", "legendLinear")
        .attr("transform", `translate(${width - 460},-100)`);
    svgh.select(".legendLinear")
        .call(legendLinear);
    svgh.selectAll('.rect').data(pdata.pdata).enter().append('rect')
        .attr('x', (d) => { return xscale(d[pdata.plotxvaluefield]) })
        .attr('y', (d) => {
            return dscale(new Date(d[pdata.plotyvaluefield]))
        })
        .attr('width', xscale.bandwidth() - 2)
        .attr('height', dscale.bandwidth() - 2)
        .attr('fill', (d) => {
            return color(+d.score)
        }).on("mousemove click touchstart", function (eventb, d) {
            tooltip
                .style("left", d3.pointer(eventb, svgh)[0] + 20 + "px")
                .style("top", d3.pointer(eventb, svgh)[1] + 1 + "px")
                .style("display", "inline-block")
                .html(pdata.disptext[0] + (d[pdata.plotvaluefield]) + pdata.disptext[1] + d[pdata.plotxvaluefield] + pdata.disptext[2] + d[pdata.plotyvaluefield]);
        }).on("mouseout touchend", function (d) { tooltip.style("display", "none"); })
    if (!pdata) {
        return (
            <div>

            </div>
        )
    } else {
        return (
            <div className='containerh' ref={wrapperRef}>
                <div ref={heatMapref}>
                    <svg>
                        <g className='x-axis' />
                        <g className='y-axis' />
                        <g className='x-label' />
                        <g className='y-label' />
                    </svg>
                </div>

            </div>
        )
    }

}
