import React , {useRef, useEffect} from 'react'
import * as d3 from 'd3'

export default function BubbleChart({pdata}) {
    function onlyUnique(value, index, self) { 
        return self.indexOf(value) === index;
    }
    let data = pdata.pdata
    const svgBubbleRef = useRef()
    const wrapperRef = useRef()
    let margin = { top: 10, right: 30, bottom: 30, left: 100 },
        width = 1000 - margin.left - margin.right,
        height = 800 - margin.top - margin.bottom;
   

    useEffect(()=>{
            let tooltip = d3.select(".containerb").append("div").attr("class", "toolTip")
            const svgb = d3.select(svgBubbleRef.current).select('svg').attr('viewBox',pdata.viewbox)
            let xScale = d3.scaleBand().domain(data.map(function (d) { return d[pdata.plotxvaluefield] }).filter(onlyUnique)).range([0,width])
            let yScale = d3.scaleBand().domain(data.map(function (d) { return d[pdata.plotyvaluefield] }).filter(onlyUnique)).range([height,0])
            const yAxis = d3.axisLeft(yScale)
            const xAxis = d3.axisBottom(xScale)
            svgb.select('.y-axis').style('transform', `translate(-20px)`).call(yAxis)
            svgb.select('.x-axis').style('transform', `translate(0px,${height + 10}px)`).call(xAxis.ticks(pdata.ticks))
            svgb.selectAll(".x-axis>.tick>text")
                .each(function (d, i) {
                    d3.select(this).style("font-size", pdata.tickfontsize);
                })
            svgb.selectAll(".y-axis>.tick>text")
                .each(function (d, i) {
                    d3.select(this).style("font-size", pdata.tickfontsize);
                })
            svgb.select('.y-label').append('text').attr('transform', `rotate(-90)`)
                .attr('y', 0 - margin.left - pdata.ylabelpositiontuner)
                .attr('x', 0 - height / 2)
                .attr('dy', '0.5em')
                .attr('text-anchor', 'middle')
                .text(pdata.ylabeltxt).style('font-size', pdata.labelfontsize).style('fill', pdata.labeltxtcolor)
            svgb.select('.x-label').append('text').attr('transform', `translate(${(width / 2)},${height + pdata.xlabelpositiontuner + margin.bottom + 30})`)
                .attr('text-anchor', 'middle')
                .text(pdata.xlabeltxt).style('font-size', pdata.labelfontsize).style('fill', pdata.labeltxtcolor)
             svgb.selectAll('.bubbles')
                .data(data)
                .enter()
                .append('circle')
                .attr('cx',(d)=>{
                    return xScale(d[pdata.plotxvaluefield])+(xScale.bandwidth()/2)
                })
                .attr('cy',(d)=>{
                    return yScale(d[pdata.plotyvaluefield])+(yScale.bandwidth()/2)
                })
                .attr('r',(d)=>{
                    return d[pdata.plotvalue]*pdata.valuescalefactor
                })
                .attr('fill',pdata.fillcolor)
                .attr('opacity',pdata.fillopacity)
                .on("mousemove click touchstart", function (eventb, d) {
                    tooltip
                        .style("left", d3.pointer(eventb, svgb)[0] + 20 + "px")
                        .style("top", d3.pointer(eventb, svgb)[1] + 1 + "px")
                        .style("display", "inline-block")
                        .html(pdata.disptext[0] + (d[pdata.plotxvaluefield]) + pdata.disptext[1] + d[pdata.plotyvaluefield] + pdata.disptext[2] + d[pdata.plotvalue]);
                }).on("mouseout touchend", function (d) { tooltip.style("display", "none"); })
        
    },[pdata])

    if (!pdata) {
        return (
            <div>
                No Data
            </div>
        )
    }else {
        return(
             <div className='containerb' ref={wrapperRef} >
                <div ref={svgBubbleRef}>
                    <svg>
                        <g className='x-axis' />
                        <g className='y-axis' />
                        <g className='x-label' />
                        <g className='y-label' />
                    </svg>
                </div>
            </div>
        )
    }
       
}
