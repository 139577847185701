import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'
import { format } from 'date-fns'

export default function LineChart({ pdata }) {
    const svgLineRef = useRef()
    const wrapperRef = useRef()
    const data = pdata.pdata
    const xtickformat = pdata.xtickformat
    let margin = { top: 10, right: 30, bottom: 30, left: 100 },
        width = 1000 - margin.left - margin.right,
        height = 800 - margin.top - margin.bottom;
    let slidemarker = pdata.sliderxvalue
    useEffect(() => {
        let tooltip = d3.select(".containerL").append("div").attr("class", "toolTip");
        const svgL = d3.select(svgLineRef.current).select('svg').attr('viewBox', pdata.viewbox)//'-80 0 1200 230')//.attr('width', width + margin.left + margin.right).attr('height', height + margin.top + margin.bottom + 70)
        const yExtent = d3.extent(data, d => +d[pdata.plotyvaluefield])
        const yScale = d3.scaleLinear().domain(yExtent).range([height, 0])
        const xExtent = d3.extent(data, d => +d[pdata.plotxvaluefield])
        const xScale = d3.scaleLinear().domain(xExtent).range([0, width])
        const yAxis = d3.axisLeft(yScale)
        let xAxis = d3.axisBottom(xScale)
        //const xAxis = d3.axisBottom(xScale).tickFormat(d3.format('d'))
        // const xAxisbase = d3.axisBottom(xScale)
        // let xAxis = null
        if (xtickformat === 'date'){
            xAxis = d3.axisBottom(xScale).tickFormat(d3.timeFormat('%b-%d'))
        }else if (xtickformat === 'number'){
            xAxis = d3.axisBottom(xScale).tickFormat(d3.format('d'))
        }
        
        const lineGenerator = d3.line()
            .x(d => xScale(+d[pdata.plotxvaluefield]))
            .y(d => yScale(+d[pdata.plotyvaluefield]))
            //.curve(d3.curveBasis)
        svgL.select('.y-axis').style('transform', `translate(-20px)`).call(yAxis)
        svgL.select('.x-axis').style('transform', `translate(0px,${height + 10}px)`).call(xAxis.ticks(pdata.ticks))
        svgL.select('.y-label').append('text').attr('transform', `rotate(-90)`)
            .attr('y', 0 - margin.left - pdata.ylabelpositiontuner)
            .attr('x', 0 - height / 2)
            .attr('dy', '0.5em')
            .attr('text-anchor', 'middle')
            .text(pdata.ylabeltxt).style('font-size', pdata.labelfontsize).style('fill', pdata.labeltxtcolor)
        svgL.select('.x-label').append('text').attr('transform', `translate(${(width / 2)},${height + pdata.xlabelpositiontuner + margin.bottom + 30})`)
            .attr('text-anchor', 'middle')
            .text(pdata.xlabeltxt).style('font-size', pdata.labelfontsize).style('fill', pdata.labeltxtcolor)
        svgL.selectAll(".x-axis>.tick>text")
            .each(function (d, i) {
                d3.select(this).style("font-size", pdata.tickfontsize);
            })
        svgL.selectAll(".y-axis>.tick>text")
            .each(function (d, i) {
                d3.select(this).style("font-size", pdata.tickfontsize);
            })
        svgL.append('path')
            .attr('d', lineGenerator(data))
            .attr('fill', 'none')
            .attr('stroke-width', pdata.defaultstrokewidth)
            .attr('stroke', pdata.defaultstrokecolor)
        svgL.selectAll('circle').data(data).exit().remove()
        svgL.selectAll('circle').data(data, key => { return +data[pdata.datauniqueid] }).exit().remove()
        svgL.selectAll('circle').data(data, key => { return +data[pdata.datauniqueid] }).enter().append('circle')
            .attr('cx', d => { return xScale(d[pdata.plotxvaluefield]) })
            .attr('cy', d => { return yScale(d[pdata.plotyvaluefield]) })
            .attr('r', d => {
                if (+d[pdata.plotyvaluefield] === yExtent[1]) {
                    return pdata.highmarkradius
                } else if (+d[pdata.plotxvaluefield] === slidemarker) {
                    return pdata.slidemarkerradius
                } else {
                    return pdata.defaultmarkradius
                }
            })
            .attr('fill', d => { if (+d[pdata.plotxvaluefield] === slidemarker) { return pdata.slidemarkercolor } else if (+d[pdata.plotyvaluefield] === yExtent[1]) { return pdata.highmarkcolor } else { return pdata.defaultmarkcolor } })
            .on("mousemove click touchstart", function (eventb, d) {
                tooltip
                    .style("left", d3.pointer(eventb, svgL)[0] + 20 + "px")
                    .style("top", d3.pointer(eventb, svgL)[1] + 1 + "px")
                    .style("display", "inline-block")
                    .html( ()=>{
                        if (pdata.plotxvaluedatefield==='Y') {
                           return  pdata.disptext[0] +   (format(d[pdata.plotxvaluefield],'dd/MM/yyyy')) + pdata.disptext[1] + d[pdata.plotyvaluefield] + pdata.disptext[2] + d[pdata.additionaldisptext[0]]
    
                         }
                         else{
                            return  pdata.disptext[0] +   (d[pdata.plotxvaluefield]) + pdata.disptext[1] + d[pdata.plotyvaluefield] + pdata.disptext[2] + d[pdata.additionaldisptext[0]]
    
                         }

                    }
                    
                       );
            }).on("mouseout touchend", function (d) { tooltip.style("display", "none"); })
    }
        , [pdata, slidemarker]);
    if (!data) {
        return (
            <div>
                No Data
            </div>
        )
    } else {
        return (
            <div className='containerL' ref={wrapperRef} >
                <div ref={svgLineRef}>
                    <svg>
                        <g className='x-axis' />
                        <g className='y-axis' />
                        <g className='x-label' />
                        <g className='y-label' />
                    </svg>
                </div>
            </div>)
    }
}